import { useState, useEffect } from 'react';
import { useUser } from '@context/UserProvider';
import { useApiService } from '@api/services';
import { ReviewHeader } from '@components/Review/ReviewHeader';
import {
  OnReviewType,
  CsamReportType,
  ModeratorReviewType,
  ViolationPayload
} from '@typeDef/Review';
import { useNavigate } from 'react-router-dom';
import {
  DomainObject,
  Action,
  ContentDecisionType,
  AuthorDecisionType
} from '@api/types';
import { getEvaluations } from '@components/Review/utils';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { UserInfo } from '@components/Review/UserInfo/index';
import { Metadata } from '@components/Review/UserInfo/Metadata';
import { Tray } from '@components/Review/Shared/Tray';
import { EventTimeline } from '@components/Review/Shared/EventTimeline';
import { DecisionTree } from '@components/Review/DecisionTree';
import { ContentInReview } from '@components/Review/Content';
import { Evaluations } from '@components/Review/Evaluations';
import { DateTime } from 'luxon';
import { Accordion } from '@components/shared/Accordion';

type onQAreviewType = {
  notes?: string;
  rootCause?: string;
  passed?: boolean;
};

const ModeratorReview = ({
  queueId,
  queue,
  contentCase,
  minorStrikes,
  majorStrikes,
  comments,
  showQueueNav = false,
  incidents,
  cases,
  strikes,
  onReviewCallback
}: ModeratorReviewType) => {
  const navigate = useNavigate();
  const { platform, domainObjects, actions } = useUser();
  const [newNotes, setNewNotes] = useState<string>();
  const [selectedProperty, setSelectedProperty] = useState<string>();
  const [showPolicyList, setShowPolicyList] = useState<boolean>(false);
  const [actionSelected, setActionSelected] = useState<Action | null>();
  const [domainObject, setDomainObject] = useState<DomainObject>();
  const [showCsamModal, setShowCsamModal] = useState<boolean>(false);
  const [csamForm, setCsamForm] = useState<CsamReportType>();
  const [isUpdatingCase, setIsUpdatingCase] = useState<boolean>(false);
  const [isUpdatingQAreview, setIsUpdatingQAreview] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(true);
  const [showTimeline, setShowTimeline] = useState<boolean>(false);
  const [payload, setPayload] = useState<ViolationPayload>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>();
  const [showConfirmRecap, setShowConfirmRecap] = useState(false);
  const {
    postReviewContent,
    postAuthorDecision,
    postReviewQA,
    postSkipContent
  } = useApiService();
  const [startReview] = useState<DateTime>(DateTime.now());

  const getHandlingTime = () => {
    const endReview = DateTime.now();
    const diffInSeconds = Math.ceil(endReview.diff(startReview).as('seconds'));
    return diffInSeconds;
  };

  const callback = {
    onSuccess: () => {
      onReviewCallback();
      setShowActions(false);
      setNewNotes('');
    },
    onError: ({ response }: any) => {
      setIsUpdatingCase(false);
      setIsUpdatingQAreview(false);
      navigate(`/error?pid=${platform?.id}`, {
        state: {
          status: response?.status,
          title: 'Oops... Something went wrong',
          description:
            'We are having some problems reaching the page you requested, please visit our home page'
        }
      });
    }
  };

  const onReview = ({ type, hint, notes, report }: OnReviewType) => {
    const handlingTime = getHandlingTime();
    setIsUpdatingCase(true);
    if (!type || !hint || !queueId) return;
    if (!actionSelected?.authorDecision) {
      postReviewContent.mutate(
        {
          hint: hint,
          queue_id: parseInt(queueId),
          violations: payload?.violations as any,
          content_cases: [
            {
              complexType: contentCase.ct,
              contentId: contentCase.cid,
              version: contentCase?.version || 0
            }
          ],
          decision: type as ContentDecisionType,
          notes,
          attachments: { csam_report: report },
          handling_time: handlingTime
        },
        callback
      );
    }
    if (actionSelected?.authorDecision) {
      postAuthorDecision.mutate(
        {
          hint: hint,
          queue_id: parseInt(queueId),
          violations: payload?.violations as any,
          decision: type as AuthorDecisionType,
          authors: [
            {
              author: contentCase?.contents?.at(-1)?.author as string,
              case: {
                complexType: contentCase.ct,
                contentId: contentCase.cid,
                version: contentCase?.version || 0
              }
            }
          ],
          notes,
          attachments: { csam_report: report },
          handling_time: handlingTime
        },
        callback
      );
    }
  };

  const onSkip = () => {
    setIsUpdatingCase(true);
    if (!contentCase.cid && !contentCase.ct) return;

    postSkipContent.mutate(
      {
        contentId: contentCase.cid,
        complexType: contentCase.ct
      },
      callback
    );
  };

  const onQAreview = (args: onQAreviewType) => {
    setIsUpdatingQAreview(true);
    const notes = args?.notes;
    const rootCause = args?.rootCause;
    const passed = args?.passed ?? true;
    const evaluatedDecision = contentCase.contentDecisions?.at(-1);
    if (!evaluatedDecision || !actionSelected?.contentDecision) return;

    postReviewQA.mutate(
      {
        complexType: contentCase.ct,
        contentId: contentCase.cid,
        version: contentCase?.version ?? 0,
        checkedDecision: {
          ts: evaluatedDecision.ts,
          moderator: evaluatedDecision.moderator
        },
        controlDecision: {
          decision: actionSelected?.contentDecision,
          reviewStarted: DateTime.now().toString(),
          violations: payload?.violations,
          hint: actionSelected.hint,
          queueId: parseInt(queueId ?? '')
        },
        passed,
        rootCause,
        notes
      },
      callback
    );
  };

  const setSelectedAction = (action?: Action) => {
    if (!action) return setActionSelected(null);
    if (
      (!!action.authorDecision && action.authorDecision !== 'act') ||
      (!!action.contentDecision && action.contentDecision !== 'act')
    ) {
      setShowConfirmRecap(true);
    }
    setShowPolicyList(true);
    return setActionSelected(action);
  };

  const copyCaseLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/review/event/${contentCase.cid}?pid=${platform?.id}`
    );
  };

  const onConfirmModalClicked = () => {
    onReview({
      type: actionSelected?.contentDecision ?? actionSelected?.authorDecision,
      hint: actionSelected?.hint,
      notes: newNotes
    });
  };

  const onConfirmCsamModalClicked = () => {
    onReview({
      hint: actionSelected?.hint,
      type: actionSelected?.contentDecision ?? actionSelected?.authorDecision,
      notes: newNotes,
      report: csamForm
    });
  };

  const setModal = (type: string) => {
    setModalType(type);
    setShowModal(true);
  };

  useEffect(() => {
    if (contentCase.contents) {
      const dom = domainObjects?.reduce((acc: any, cur: any) => {
        acc[cur.type] = cur;
        return acc;
      }, {})[contentCase.ct];
      setDomainObject(dom);
    }

    if (contentCase) {
      setShowCsamModal(false);
      setSelectedAction();
      setIsUpdatingCase(false);
      setShowActions(true);
      setShowModal(false);
    }
  }, [contentCase]);

  useEffect(() => {
    if (contentCase?.contents?.length) {
      const fieldId =
        getEvaluations(
          contentCase?.contents?.at(-1)?.evaluations,
          contentCase?.contents?.at(-1)?.violations || [],
          contentCase?.manualEvaluations
        )?.[0]?.evaluation?.at(-1)?.field ??
        contentCase?.contents?.at(-1)?.fields?.at(-1)?.id;
      fieldId !== '*' && setSelectedProperty(fieldId);
    }
  }, [contentCase]);

  if (!contentCase) {
    navigate(`/queues?pid=${platform?.id}`);
    return null;
  }

  if (!contentCase) return null;

  const props = {
    contentCase,
    incidents,
    cases,
    strikes,
    selectedProperty,
    setSelectedProperty,
    showActions,
    showPolicyList,
    setShowPolicyList,
    onSkip,
    showCsamModal,
    csamForm,
    setCsamForm,
    onConfirmCsamModalClicked,
    setShowCsamModal,
    domainObject,
    actionSelected,
    setSelectedAction,
    payload,
    setPayload,
    isUpdatingCase,
    isUpdatingQAreview,
    onConfirmModalClicked,
    showModal,
    hideModal: () => setShowModal(false),
    setShowModal,
    modalType,
    setModalType: setModal,
    comments,
    newNotes,
    setNewNotes,
    setShowTimeline,
    showTimeline,
    metadata: contentCase?.contents?.at(-1)?.metadata,
    majorStrikes,
    minorStrikes,
    userId: contentCase?.contents?.at(-1)?.author,
    qa: queue?.qa,
    isBlindReview: queue?.blind,
    queue,
    onQAreview,
    showConfirmRecap,
    setShowConfirmRecap,
    availableActions: contentCase.availableActions?.map((x: string) =>
      actions?.find((action: Action) => action.hint == x)
    )
  };

  return (
    <HotkeysProvider>
      <div className="bg-custom-bg">
        <div className="flex flex-col mlg:flex-row mlg:flex-grow">
          <div className="w-full">
            <ReviewHeader
              title={queue?.name}
              showControls={showQueueNav}
              onSubmit={() => setModal('skip')}
              copyCaseLink={copyCaseLink}
              contentId={contentCase.cid}
            />
            <div className="p-6 flex justify-center">
              <div className="sm:block md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 md:gap-8 w-full px-2">
                <div className="hidden 2xl:block">
                  <UserInfo {...props} />
                  <Metadata {...props} />
                </div>

                <div className="col-span-2">
                  <ContentInReview {...props} />
                  <div className="2xl:hidden">
                    <Accordion
                      title="User History"
                      hasDivider={!!props?.metadata?.length}
                    >
                      <UserInfo {...props} showHeader={false} />
                    </Accordion>
                    <Accordion
                      title="Metadata"
                      show={!!props?.metadata?.length}
                      hasDivider={false}
                    >
                      <Metadata {...props} showHeader={false} />
                    </Accordion>
                  </div>
                </div>

                <div className="hidden lg:block">
                  <Evaluations {...props} />
                </div>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-300 px-8 min-h-screen flex-shrink-0 w-full mlg:w-[340px] mlg:p-4 ">
            <div className="lg:hidden">
              <Accordion
                title="Potential Violation"
                show={!!contentCase?.contents?.at(-1)?.evaluations}
              >
                <Evaluations {...props} showHeader={false} />
              </Accordion>
            </div>

            <DecisionTree {...props} />
          </div>
        </div>
        <Tray show={showTimeline} close={() => setShowTimeline(false)}>
          <div className="p-4">
            <EventTimeline contentCase={contentCase} />
          </div>
        </Tray>
      </div>
    </HotkeysProvider>
  );
};

export { ModeratorReview };
