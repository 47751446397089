import { useEffect, useState } from 'react';
import { QueueCard } from '@components/Queues/QueueCard';
import { useUser } from '@context/UserProvider';
import { getGroupedQueues } from '@components/Review/utils';
import { Loader } from '@components/shared/Loader';
import { useQueues } from '@context/QueuesProvider';
import { QueueGroups } from '@typeDef/queues';
import '@components/style.css';
import { capitalizeFirstLetter } from '@utils/string';
import { DateTime } from 'luxon';
import { Dropdown } from '@components/shared/Dropdown';
import { Legend } from '@components/Queues/Legend';
import { Header } from '@components/shared/Header';

const QueueCount = () => {
  const { platform } = useUser();
  const { error, mappedQueues, isLoadingQueues, isLoadingCount } = useQueues();
  const [pageFilter, setPageFilter] = useState();
  const [groupedQueues, setGroupedQueues] = useState<any>();

  useEffect(() => {
    const sortBy = {
      'Earliest Content': (a: any, b: any) =>
        DateTime.fromISO(a?.minTs).toMillis() -
        DateTime.fromISO(b?.minTs).toMillis(),
      'Most Incidents': (a: any, b: any) => b?.count - a?.count
    };
    if (pageFilter && sortBy[pageFilter]) {
      const sortedObject: QueueGroups = {};

      for (const key in groupedQueues) {
        if (groupedQueues.hasOwnProperty(key)) {
          sortedObject[key] = groupedQueues[key].sort(sortBy[pageFilter]);
        }
      }
      setGroupedQueues(sortedObject);
    }
  }, [pageFilter]);

  useEffect(() => {
    if (mappedQueues) {
      const grouped = getGroupedQueues(mappedQueues?.filter((x) => !!x?.count));
      grouped && setGroupedQueues(grouped);
    }
  }, [mappedQueues]);

  if (error || !platform) return <div>Please reload</div>;

  if (isLoadingQueues || isLoadingCount) return <Loader />;

  if (!groupedQueues || !mappedQueues || !Object.keys(groupedQueues)?.length)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <img src="./assets/images/no-queue.svg" alt="No Queue" />
        <p className="mt-4 text-xl font-semibold">
          No content to review right now
        </p>
        <p className="mt-2 text-sm font-medium text-gray">
          Please try again later
        </p>
      </div>
    );
  return (
    <div className="bg-custom-bg h-full overflow-auto">
      <Header>
        <h2>Queues</h2>
      </Header>
      <div className="flex justify-center w-full px-8">
        <div className="w-[1500px]">
          <div className="flex justify-between h-[80px]">
            <div className="flex items-center gap-3 relative">
              <span>Sort by:</span>
              <Dropdown
                title={pageFilter || 'Please select'}
                list={['Earliest Content', 'Most Incidents']}
                onAction={setPageFilter}
                buttonStyle="border border-neutral-200 w-44 p-2 rounded-md"
                listStyle="absolute zIndexTop w-44 overflow:hidden border border-neutral-200 rounded-md overflow-auto"
                itemStyle="p-2.5 rounded-0 overflow:hidden"
              />
            </div>
            <div className="block md:flex items-center gap-3">
              <Legend />
            </div>
          </div>
          <div>
            {Object.keys(groupedQueues)?.map((key: string) => (
              <div key={'QueueGroup' + key} className="flex mt-8 flex-col">
                <h3>{capitalizeFirstLetter(key)}</h3>
                <div key={key} className="my-4 flex flex-col">
                  {groupedQueues[key]?.map((queue: any) => (
                    <QueueCard
                      key={queue?.id}
                      platform={platform}
                      queue={queue?.id}
                      title={queue?.name}
                      count={queue?.count}
                      ageBuckets={[
                        { key: '48h-', value: queue.oldestCount },
                        { key: '12h-48h', value: queue.intermediateCount },
                        { key: '-12h', value: queue.recentCount }
                      ]}
                      earliestEvent={queue?.minTs}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueCount;
