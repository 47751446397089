import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react';
import { CognitoUser, Auth } from '@aws-amplify/auth';

interface AuthContextInterface {
  auth: CognitoUser | undefined;
  getToken(): Promise<string | any>;
}

const AuthContext = createContext({} as AuthContextInterface);

interface Props {
  children: ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = useState<CognitoUser>();

  const checkUser = async (bypassCache: any = false) => {
    try {
      const auth = await Auth.currentAuthenticatedUser({ bypassCache });
      if (auth) {
        setAuth(auth);
      }
    } catch (error) {
      setAuth(undefined);
    }
  };

  const getToken = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      return session?.getIdToken().getJwtToken();
    } catch (e: any) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    // Adding custom error
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
