import { useEffect, useState } from 'react';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import { Chart } from 'react-chartjs-2';
import {
  ModeratorListType,
  SchedulerProps,
  SchedulerRawDataType,
  ModeratorSchedulerType
} from '@typeDef/Dashboard';
import { Moderator } from '@api/types';
import { getFullNameFromEmail } from '@utils/string';
import { useUser } from '@context/UserProvider';

const COLOURS = ['#6366F1', '#0139AF', '#03C7E2', '#7000FF', '#EE37FA'];

const Scheduler = ({
  rawData,
  moderatorsDetails,
  errorFetchingData
}: SchedulerProps) => {
  const { platform, theme } = useUser();
  const [list, setList] = useState<ModeratorListType[]>([]);

  const getModeratorData = (moderator?: Moderator) => {
    if (!moderator) return;

    const moderatorList = [] as Array<ModeratorSchedulerType>;
    rawData?.map((x: SchedulerRawDataType) => {
      if (Object.keys(x.value).length) {
        for (const [key, value] of Object.entries(x.value) as any) {
          if (moderator?.id === key) {
            for (const v of value.intervalsWorked as string) {
              const dt = DateTime.fromISO(v).setZone(platform?.timezone);
              moderatorList.push({
                date: x.key,
                time: dt,
                val: parseFloat(`${dt.toFormat('HH.mm')}`),
                moderator
              });
            }
          }
        }
      } else {
        moderatorList.push({
          date: x.key,
          val: null
        });
      }
    });
    return moderatorList;
  };

  const getChartData = (data: SchedulerRawDataType[]) => {
    const moderatorList = [] as ModeratorListType[];

    data?.map((x: SchedulerRawDataType) => {
      const { length } = Object.keys(x.value);
      if (length) {
        Object.keys(x.value).forEach((modId) => {
          const modDetails = moderatorsDetails?.find(
            (m: Moderator) => m?.id === modId
          );

          if (
            !moderatorList.some(
              (x: ModeratorListType) => x?.id === modDetails?.id
            )
          ) {
            moderatorList.push({
              email: modDetails?.email,
              id: modDetails?.id,
              data: getModeratorData(modDetails)
            });
          }
        });
      }
    });
    return moderatorList;
  };

  useEffect(() => {
    if (rawData && moderatorsDetails) {
      const moderatorList = getChartData(rawData);
      setList(moderatorList);
    }
  }, [rawData]);

  const getHourWorked = (time: DateTime) => {
    const from = time.toFormat('T');
    const to = time.plus({ minutes: 15 }).toFormat('T');
    return `     * ${from} - ${to}\n`;
  };

  const formatData = (arr: any) =>
    arr?.map((x: any) => ({
      x: DateTime.fromISO(x.date),
      y: x.val && [x.val, x.val + 0.6]
    }));

  const getTimeInterval = (list: any) => {
    const totalMinutes = list.length * 15;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}:${minutes}`;
  };

  if (!rawData || errorFetchingData || !list) return null;

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    borderColor: COLOURS,
    scales: {
      x: {
        ticks: { color: `${theme === 'dark' && 'white'}` },
        offset: true,
        grid: { display: true },
        border: { display: false },
        type: 'timeseries',
        time: { unit: 'day' }
      },
      y: {
        grid: { display: false },
        border: { display: false },
        min: 0,
        max: 24,
        ticks: {
          beginAtZero: true,
          stepSize: 3,
          color: `${theme === 'dark' && 'white'}`
        }
      }
    },
    plugins: {
      legend: {
        onHover: (event: any) => {
          event.native.target.style.cursor = 'pointer';
        },
        onLeave: (event: any) => {
          event.native.target.style.cursor = 'default';
        },
        labels: {
          useBorderRadius: true,
          borderRadius: 6,
          boxHeight: 14,
          color: `${theme === 'dark' && 'white'}`
        }
      },
      tooltip: {
        callbacks: {
          labelColor: () => ({}),
          label: () => null,
          footer: (context: any) => {
            const date = DateTime.fromMillis(context[0].parsed.x);
            const filteredList = list
              .filter(
                (x: ModeratorListType) =>
                  getFullNameFromEmail(x?.email) === context[0].dataset.label
              )[0]
              .data?.filter(
                (x: any) =>
                  date.toISODate() === DateTime.fromISO(x.date).toISODate()
              );

            const totTimeWorked = getTimeInterval(filteredList);
            const intervalBreakDown = filteredList?.map((x: any) =>
              getHourWorked(x.time)
            );
            return `  Total hour: ${totTimeWorked} \n${intervalBreakDown?.join(
              ''
            )}`;
          },
          title: (context: any) => {
            const [{ email }] = list.filter(
              (x: any) =>
                getFullNameFromEmail(x.email) === context[0].dataset.label
            );
            return `${getFullNameFromEmail(email)}`;
          }
        }
      }
    }
  };

  const chartData = {
    datasets: list.map((x: ModeratorListType, index: number) => ({
      label: getFullNameFromEmail(x.email),
      data: formatData(x.data) || [],
      backgroundColor: COLOURS[index % COLOURS.length]
    }))
  };

  return (
    <div className="moderatorHoursChart w-full mlg:min-w-[64%]">
      <h4>Moderator Hours</h4>
      <Chart
        data-testid="chart"
        type="bar"
        options={options as any}
        data={chartData}
      />
    </div>
  );
};

export { Scheduler };
