import { useUser } from '@context/UserProvider';
import { Header } from '@components/shared/Header';
import { useApiService } from '@api/services';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Loader } from '@components/shared/Loader';
import { QueueInfo } from '@api/types';

const QueuesSettings = () => {
  const { useQueues } = useApiService();
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid') || '';
  const platformSection = searchParams.get('pid-setting') || '';
  const {
    data: queues,
    isLoading,
    error
  } = useQueues({ enabled: false, platformId: platformSection });
  const { platforms } = useUser();
  const platform = platforms?.find((p) => p.id.toString() == platformId);

  if (isLoading) return <Loader />;
  if (error || !queues || !platform) return <div>Please try later</div>;

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">{platform.name}</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">Queues</h2>
        </div>
      </Header>
      <div
        className="mb-4 grid gap-4 px-8 pt-8"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))'
        }}
      >
        {queues.map((queue: QueueInfo) => (
          <NavLink
            className="text-text-white bg-primary-50 rounded-lg flex flex-col gap-3 p-6 text-left flex-grow min-w-[260px]"
            to={`${queue.id.toString()}?pid=${platformId}&pid-setting=${platformSection}`}
            key={queue.id}
          >
            <h5>{queue.name}</h5>
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default QueuesSettings;
