import { Moderator, ModeratorTimeTrackBucket } from '@api/types';
import { ActiveModeratorReturnType } from '@typeDef/Dashboard';
import { DateTime } from 'luxon';

const getKebabCase = (val: string) =>
  val
    .split('-')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');

const getDailySummaryData = (arr: any) => {
  const columns: any[] = [
    {
      dataField: 'date',
      text: 'Date'
    }
  ];
  const data: any[] = [];

  arr.map((item: any) => {
    const dailySummaryItem: any = {
      date: DateTime.fromISO(item.date).toFormat('DD')
    };

    item?.summary?.map((s: any) => {
      dailySummaryItem[s.slug] =
        s.format === 'percent'
          ? ((s.value || 0) * 100).toFixed(2) + '%'
          : s.value;
    });

    data.push(dailySummaryItem);
  });

  arr[0]?.summary?.map((s: any) => {
    columns.push({
      dataField: s.slug,
      text: s.name
    });
  });

  const columnsNormalised = Object.values(columns).map(
    (item: any) => item.text
  );

  return {
    columns: columnsNormalised,
    tableData: data
  };
};

const getActiveModerators = (
  buckets: ModeratorTimeTrackBucket[] | undefined,
  moderators: Moderator[] | undefined
): Moderator[] => {
  if (!moderators) return [];
  const moderatorContributions = moderators?.map(
    (m: Moderator): ActiveModeratorReturnType => ({
      moderator: m,
      count: buckets?.reduce(
        (previous: number, bucket: ModeratorTimeTrackBucket) => {
          return previous + (bucket.value[m.id]?.count || 0);
        },
        0
      )
    })
  );

  return moderatorContributions
    .filter((contribution: any) => contribution?.count > 0)
    .map((contribution: any) => contribution?.moderator);
};

const getActiveModeratorBuckets = (
  buckets: ModeratorTimeTrackBucket[] | undefined,
  moderators: Moderator[] | undefined
) => {
  if (!moderators) return [];
  const activeModeratorIds = moderators?.map((m: Moderator) => m.id);

  return buckets?.map((bucket: ModeratorTimeTrackBucket) => ({
    key: bucket.key,
    value: Object.fromEntries(
      Object.entries(bucket.value).filter(([key]: any) =>
        activeModeratorIds.includes(key)
      )
    )
  }));
};

const getMetricsDescription = (mainVal: number, optional?: number) => {
  if (!mainVal) return;

  const formattedVal = parseFloat((mainVal * 100).toFixed(2)).toString();

  return optional ? `${formattedVal}% of ${optional}` : `${formattedVal}%`;
};

export {
  getKebabCase,
  getDailySummaryData,
  getActiveModerators,
  getActiveModeratorBuckets,
  getMetricsDescription
};
