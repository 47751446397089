import { Dropdown } from '@components/shared/Dropdown';
import { useUser } from '@context/UserProvider';
import { FiSearch } from 'react-icons/fi';
import { TbFilterOff } from 'react-icons/tb';
import { RxCross1 } from 'react-icons/rx';
import { getFullNameFromEmail } from '@utils/string';
import { useEffect, useRef, useState } from 'react';
import { assignHotkey } from '@components/Review/utils';
import { useQueues } from '@context/QueuesProvider';
import {
  defaultFilters,
  getInputSearchOption
} from '@components/Explore/helper';

const FilterInput = ({ k, value, searchFilters, removeFilter }: any) => {
  const val = searchFilters?.get(k)?.get(value)?.name;
  if (!val) return null;

  return (
    <div
      key={`${k}-${value}`}
      className="roundedContainer p-1 flex bg-primary-300 text-sm font-medium w-[128px] overflow-hidden mr-0.5 justify-between"
    >
      <div className="overflow-hidden text-ellipsis whitespace-nowrap pl-1">
        {getFullNameFromEmail(val)}
      </div>
      <div className="pr-1">
        <RxCross1
          size={12}
          className="hover:cursor-pointer stroke-1"
          onClick={() => removeFilter(value, k)}
        />
      </div>
    </div>
  );
};

const SearchFilter = ({
  searchDocument,
  inputQuery,
  internalState,
  setInternalState
}: {
  searchDocument: () => void;
  inputQuery: string;
  internalState: any;
  setInternalState: any;
}) => {
  const { queues } = useQueues();
  const [showFilters, setShowFilters] = useState(false);
  const { moderators, user, domainObjects, policies } = useUser();

  const menuRef: any = useRef();
  const inputRef: any = useRef();

  const searchFilters = getInputSearchOption({
    moderators,
    queues,
    policies,
    isAdmin: user?.isUserAdmin,
    domainObjects
  });

  const handleSearch = (value: string) => {
    const state = new Map(internalState);
    state.set('q', value);
    setInternalState(new Map(state));
  };

  const onSelectFilter = (category: string, option: string) => {
    const state: any = new Map(internalState);
    if (category === 'qualityCheck' || category === 'status') {
      state.set(category, option);
      return setInternalState(new Map(state));
    }

    if (state.has(category)) {
      state.get(category).add(option);
      return setInternalState(new Map(state));
    }
    if (!state.has(category)) {
      state.set(category, new Set([option]));
      return setInternalState(new Map(state));
    }
  };

  const removeFilter = (val: string, key: string) => {
    const state: any = new Map(internalState);
    key === 'qualityCheck' || key === 'status'
      ? state.delete(key)
      : state.get(key).delete(val);
    setInternalState(new Map(state));
  };

  const clearFilter = () => {
    const pid = internalState.get('pid');
    setInternalState(new Map().set('pid', pid));
  };

  const performSearch = () => {
    searchDocument();
    setShowFilters(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      performSearch();
    }
  };
  assignHotkey('enter', performSearch, false, 'search');

  useEffect(() => {
    const handler = (e: any) => {
      if (
        !menuRef?.current?.contains(e.target) &&
        !inputRef.current.contains(e.target)
      ) {
        setShowFilters(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  return (
    <div className="w-full relative">
      <div
        className="exploreInput flex flex-row h-11 w-full bg-custom-bg border-neutral-200"
        ref={inputRef}
      >
        {[...internalState.entries()].map(([key, value]) => {
          if (key === 'qualityCheck' || key === 'status')
            return (
              <FilterInput
                key={`${key}-${value}`}
                k={key}
                value={value}
                removeFilter={removeFilter}
                searchFilters={searchFilters}
              />
            );

          if (defaultFilters.includes(key)) {
            return [...value]?.map((v: string) => (
              <FilterInput
                key={`${key}-${v}`}
                k={key}
                value={v}
                removeFilter={removeFilter}
                searchFilters={searchFilters}
              />
            ));
          }
        })}
        <input
          type="text"
          className="w-full ml-1 bg-custom-bg"
          placeholder="Search content"
          onClick={() => setShowFilters(true)}
          onChange={(e) => handleSearch(e.target.value)}
          onKeyDown={handleKeyPress}
          value={inputQuery}
        />
      </div>
      <div
        className={`absolute max-w-max roundedContainer top-[43px] min-w-[360px] p-2 bg-custom-bg zIndexTop ${
          showFilters ? 'visible' : 'invisible'
        } max-h-[500px] overflow-y-auto`}
        ref={menuRef}
      >
        <div className="border-b-gray border-b-[1px] pb-1 font-bold mb-1">
          <span>Filters</span>
          <TbFilterOff
            size={18}
            className="float-end cursor-pointer mr-2 text-cta rounded-1 min-w-max"
            onClick={clearFilter}
          />
          <FiSearch
            size={18}
            className="float-end cursor-pointer mr-2 text-cta rounded-1 min-w-max"
            onClick={performSearch}
          />
        </div>
        {!!searchFilters &&
          [...searchFilters?.keys()]?.map((category: string) => (
            <div className="mb-1" key={category}>
              <Dropdown
                title={category}
                keepOpen={true}
                list={[...searchFilters?.get(category).values()] || []}
                onAction={(val: any) => onSelectFilter(category, val.id)}
                mainClass="p-0 w-full"
                buttonStyle="p-1 indent-1 font-semibold hover:bg-custom-bg hover:text-cta"
                itemStyle="hover:bg-custom-bg hover:text-cta active:bg-gray/10"
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export { SearchFilter };
