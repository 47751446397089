import { useEffect } from 'react';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import { Button } from '@components/shared/Buttons';
import { Loader } from '@components/shared/Loader';
import { Action } from '@api/types';
import { PolicySelectedList } from '@components/Review/Shared/PolicySelectedList';
import { assignHotkey } from '@components/Review/utils';
import { ViolationPayload } from '@typeDef/Review';

type ReviewActionsProps = {
  actionSelected: Action;
  setSelectedAction: () => void;
  onConfirmAction: () => void;
  setShowPolicyList: (val: boolean) => void;
  isUpdatingEvent: boolean;
  payload: ViolationPayload;
  setShowConfirmRecap: (val: boolean) => void;
  setNewNotes: (val: string) => void;
  newNotes: string;
  isBlindReview: boolean;
};

const ConfirmationRecap = ({
  actionSelected,
  setSelectedAction,
  onConfirmAction,
  setShowPolicyList,
  isUpdatingEvent,
  payload,
  setShowConfirmRecap,
  setNewNotes,
  newNotes,
  isBlindReview
}: ReviewActionsProps) => {
  const { enableScope, disableScope } = useHotkeysContext();

  const onSubmit = () => {
    setShowConfirmRecap(false);
    return onConfirmAction();
  };

  useEffect(() => {
    enableScope('confirmAction');
    disableScope('actions');
    disableScope('policies');
    return () => disableScope('confirmAction');
  }, []);

  useHotkeys(
    'enter',
    () => {
      if (actionSelected) {
        return onSubmit();
      }
    },
    { scopes: 'confirmAction' }
  );
  assignHotkey(
    'esc',
    () => {
      setShowConfirmRecap(false);
      if (
        actionSelected?.contentDecision === 'act' ||
        actionSelected?.authorDecision === 'act'
      )
        return setShowPolicyList(true);
      return setSelectedAction();
    },
    false,
    'confirmAction'
  );

  return (
    <div className="mt-4">
      <div className="mb-2">
        <div className="font-semibold mt-3">Decision selected:</div>
        <div className="ml-8 my-2">{actionSelected?.name}</div>
        {(actionSelected?.contentDecision === 'act' ||
          actionSelected?.authorDecision === 'act') && (
          <PolicySelectedList payload={payload?.violations} />
        )}
        {!isBlindReview && (
          <div className="roundedContainer p-0 mb-2">
            <textarea
              placeholder="Please leave notes here..."
              className="bg-custom-bg h-full border-0 w-full p-2 resize-none block overflow-y-auto focus:outline-none"
              onChange={(event) =>
                setNewNotes && setNewNotes(event.target.value)
              }
              value={newNotes}
            />
          </div>
        )}
        {!isUpdatingEvent ? (
          <Button
            style="button w-full text-center font-bold py-2"
            title="Confirm"
            onClick={onSubmit}
            disabled={!actionSelected}
          />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export { ConfirmationRecap };
