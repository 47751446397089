import { ReactNode, useEffect, useState } from 'react';
import { useAnalytics } from '@context/AnalyticsProvider';
import { Tooltip } from './Tooltip/Tooltip';

type SubmitButtonProp = {
  onClose?: () => void;
  onSubmit: (e?: any) => void;
  submitLabel: string;
  isLoading: boolean;
  submitStyle?: string;
  disabled?: boolean;
};

type ButtonProp = {
  style?: string;
  title?: string;
  hiddenTitle?: string;
  onClick: (e?: any) => void;
  hover?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  tooltip?: string;
  tooltipStyle?: string;
  type?: HTMLButtonElement['type'];
  tooltipDelay?: number;
};

const Button = ({
  style,
  title,
  hiddenTitle,
  onClick,
  hover,
  children,
  disabled,
  tooltip,
  tooltipStyle,
  type,
  tooltipDelay
}: ButtonProp) => {
  const { sendEvent } = useAnalytics();
  const [isKeyHeld, setIsKeyHeld] = useState(false);
  const [pressedKey, setPressedKey] = useState<string | null>(null);
  const [middleClick, setMiddleClick] = useState<boolean>(false);

  const action = title ?? hiddenTitle;

  useEffect(() => {
    document.addEventListener('auxclick', handleMiddleClick);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('auxclick', handleMiddleClick);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleKeyDown = (event: KeyboardEvent) => {
    setIsKeyHeld(true);
    setPressedKey(event.key);
  };

  const handleKeyUp = () => setIsKeyHeld(false);

  const handleMiddleClick = (event: MouseEvent) => {
    if (event.button === 1) {
      setMiddleClick(true);
    }
  };

  const onClickFn = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isKeyHeld) {
      if (pressedKey === 'Control' || pressedKey === 'Meta' || middleClick) {
        sendEvent('clickEvent - newTab', { action });
        return onClick(event);
      }
    }
    sendEvent('clickEvent', { action });
    return onClick(event);
  };

  const handleClick: any = hover ? null : onClickFn;

  const handleMouseEnter: any = hover ? onClickFn : null;

  return (
    <Tooltip
      content={tooltip}
      placement={`bottom min-h-[44px] ${tooltipStyle}`}
      delay={tooltipDelay}
    >
      <button
        name={action}
        disabled={disabled}
        className={style}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        data-testid={title ?? hiddenTitle}
        type={type ?? undefined}
      >
        {title} {children}
      </button>
    </Tooltip>
  );
};

const SubmitButtons = ({
  onClose,
  onSubmit,
  submitLabel,
  isLoading,
  submitStyle,
  disabled = false
}: SubmitButtonProp) =>
  isLoading ? (
    <div className="relative">
      <div className="flexSpaceBetween items-center w-max">
        <div
          className="spinner h-8 w-8 translate-x-2/4"
          data-testid="loader"
        ></div>
        <div className="w-max">
          <h4>Loading...</h4>
        </div>
      </div>
    </div>
  ) : (
    <>
      {onClose && (
        <Button
          style="buttonOutlined font-semibold"
          onClick={onClose}
          title="Close"
        />
      )}
      <Button
        style={`button ${submitStyle}`}
        disabled={disabled}
        onClick={onSubmit}
        title={submitLabel}
      />
    </>
  );

export { SubmitButtons, Button };
