import { useEffect, useState, ChangeEvent } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { assignHotkey } from '@components/Review/utils';
import { Dropdown } from '@components/shared/Dropdown';
import { useUser } from '@context/UserProvider';
import { FaInfoCircle } from 'react-icons/fa';
import { Policy, FieldViolation, Content, ContentField } from '@api/types';
import '@components/style.css';
import { Dialog } from '@components/shared/Dialog';
import { marked } from 'marked';
import { Button } from '@components/shared/Buttons';
import { FaCheck } from 'react-icons/fa6';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';

type PolicyListProps = {
  onClick: (val: string) => void;
  violations: Set<string>;
  confirmPolicies: () => void;
  setSelectedAction: (val: object) => void;
};

const getField = (
  field: ContentField,
  parentId?: string
): string | string[] => {
  if (field.fields) return getFields(field.fields, field.id).concat(field.id);
  return parentId ? `${parentId}.${field.id}` : field.id;
};

const getFields = (fields: ContentField[], parentId?: string) => {
  return fields.map((field: ContentField) => getField(field, parentId)).flat();
};

const getProperties = (content: Content) => {
  if (!content) return [];
  if (content.fields) return getFields(content.fields);
  if (content.body) return Object.keys(content.body);
  return [];
};

type FieldsViolationType = {
  [key: string]: Set<string>;
};

const getPropertyList = (list: string[] = []): FieldsViolationType =>
  list.reduce((a, v) => ({ ...a, [v]: new Set() }), {});

const getViolations = (fieldViolations: FieldsViolationType) => {
  const list: FieldViolation[] = [];
  for (const [key, value] of Object.entries(fieldViolations)) {
    if (value instanceof Set) {
      const policyArray = [...value]; //
      policyArray.forEach((policy: string) =>
        list.push({
          policy: policy,
          field: key
        })
      );
    }
  }
  return { violations: list };
};

const PoliciesSelection = ({
  contentCase,
  selectedProperty,
  setSelectedProperty,
  setPayload,
  showConfirmRecap,
  setShowPolicyList,
  showPolicyList,
  setShowConfirmRecap,
  setSelectedAction
}: any) => {
  const [multiPolicySelection, setMultiPolicySelection] = useState<boolean>(
    window.localStorage.getItem('multiPolicySelection') === 'true'
  );
  const fields = getProperties(contentCase?.contents?.at(-1));
  const [fieldViolations, setFieldViolations] = useState<FieldsViolationType>(
    getPropertyList(fields)
  );

  const [violations, setViolations] = useState(new Set<string>());

  const onClick = (policyId: string) => {
    const violations = fieldViolations[selectedProperty] || new Set();

    if (violations.has(policyId)) {
      violations.delete(policyId);
    } else {
      violations.add(policyId);
    }

    setFieldViolations({ ...fieldViolations, [selectedProperty]: violations });
  };

  const onSelectPolicy = (policyId: string) => {
    violations.has(policyId)
      ? violations.delete(policyId)
      : violations.add(policyId);

    setViolations(new Set(violations));
  };

  useEffect(() => {
    window.localStorage.setItem(
      'multiPolicySelection',
      multiPolicySelection.toString()
    );
  }, [multiPolicySelection]);

  useEffect(() => {
    const payload = multiPolicySelection
      ? getViolations(fieldViolations)
      : { violations: [...violations]?.map((policy: string) => ({ policy })) };

    setPayload(payload);
  }, [fieldViolations, violations]);

  useEffect(() => {
    multiPolicySelection
      ? setViolations(new Set())
      : setFieldViolations(getPropertyList(fields));
  }, [multiPolicySelection]);

  useEffect(() => {
    return () => {
      setFieldViolations({});
      setViolations(new Set());
      setPayload();
    };
  }, []);

  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.blur();
    setMultiPolicySelection(!multiPolicySelection);
  };

  const confirmPolicies = () => {
    if (violations?.size) {
      setShowConfirmRecap(true);
      return setShowPolicyList(false);
    }

    const hasFieldViolations = Object.values(fieldViolations).some(
      (x: Set<string>) => Boolean(x?.size)
    );

    if (hasFieldViolations) {
      setShowConfirmRecap(true);
      return setShowPolicyList(false);
    }
  };

  return (
    <>
      <div className="flex items-center mt-4">
        <div
          className={`${
            showConfirmRecap ? 'bg-cta' : 'ring-1 ring-cta bg-custom-bg'
          } flex-shrink-0 w-6 h-6 rounded-lg text-white flex items-center justify-center`}
        >
          {showConfirmRecap && <FaCheck />}
        </div>
        <div className="flex justify-between w-full items-center">
          <div className="ml-3 text-lg font-semibold">
            Select policy violations{' '}
          </div>
          <Slider
            handleClick={handleClick}
            multiPolicySelection={multiPolicySelection}
            fields={fields}
          />
        </div>
      </div>
      {showPolicyList && (
        <div className="mt-4">
          {!multiPolicySelection ? (
            <div className="relative before:absolute before:ml-[0.75rem] before:h-full before:border-l-[2px] before:border-dotted before:border-white before:bg-cta">
              <div className="ml-6">
                <PolicyList
                  onClick={onSelectPolicy}
                  confirmPolicies={confirmPolicies}
                  violations={violations}
                  setSelectedAction={setSelectedAction}
                />
                <Button
                  style="flex buttonOutlined gap-2 justify-center items-center font-bold h-9 mt-2 w-full"
                  onClick={confirmPolicies}
                  disabled={violations.size === 0}
                >
                  <kbd>
                    <BsArrowReturnLeft />
                  </kbd>{' '}
                  Confirm
                </Button>
              </div>
            </div>
          ) : (
            <div className="relative mt-1 before:absolute before:ml-[0.75rem] before:h-full before:border-l-[2px] before:border-dotted before:border-white before:bg-cta">
              <div className="ml-6">
                <Dropdown
                  list={fields}
                  buttonStyle="hover:bg-custom-bg rounded-sm prevent-highlight"
                  onAction={setSelectedProperty}
                  title={selectedProperty}
                  listStyle="absolute z-10 ml-[-15px] w-56 mt-2 border-2 rounded-sm overflow-hidden border-neutral-200"
                  itemStyle="hover:bg-primary-50 prevent-highlight"
                  mainClass="roundedContainer rounded-sm mb-2 mx-2 w-full bg-custom-bg"
                />
                <PolicyList
                  onClick={onClick}
                  confirmPolicies={confirmPolicies}
                  violations={fieldViolations[selectedProperty]}
                  setSelectedAction={setSelectedAction}
                />
                <Button
                  style="flex buttonOutlined gap-2 justify-center items-center font-bold h-9 mt-2 w-full"
                  onClick={confirmPolicies}
                  hiddenTitle={'policiesConfirmedBtn'}
                  disabled={
                    !Object.values(fieldViolations).filter(
                      (x: any) => !!x?.size
                    ).length
                  }
                >
                  <kbd>
                    <BsArrowReturnLeft />
                  </kbd>{' '}
                  Confirm
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const PolicyList = ({
  onClick,
  violations,
  confirmPolicies,
  setSelectedAction
}: PolicyListProps) => {
  const { policies } = useUser();
  const { enableScope, disableScope } = useHotkeysContext();
  const [selectedDialog, setSelectedDialog] = useState<string>('');

  useEffect(() => {
    enableScope('policies');
    disableScope('actions');
    disableScope('confirmAction');
    return () => {
      enableScope('actions');
      disableScope('policies');
    };
  }, []);

  const getBind = (x: number) => {
    if (x < 10) return x.toString();
    if (x < 19) return `ctrl+${(x - 9).toString()}`;
    return `shift+${(x - 18).toString()}`;
  };

  assignHotkey('esc', setSelectedAction, '', 'policies');
  assignHotkey('enter', confirmPolicies, '', 'policies');

  const list = policies?.map((x: Policy) => ({
    ...x,
    rank: policies?.length - x.rank + 1
  }));

  list?.map((x: Policy) =>
    assignHotkey(getBind(x.rank), onClick, x.code, 'policies')
  );

  const onSelectPolicyInfo = (e: React.MouseEvent, code: string) => {
    e.stopPropagation();
    setSelectedDialog(code);
  };

  const getItemRank = (rank: number) => {
    if (rank < 10) {
      return <kbd className="mr-2">{rank}</kbd>;
    } else if (rank < 19) {
      return <kbd className="mr-2">ctrl+{rank - 9}</kbd>;
    } else {
      return <kbd className="mr-2">shift+{rank - 18}</kbd>;
    }
  };

  if (!list) return null;
  return (
    <div autoFocus={true}>
      <div className="overflow-auto">
        {list
          .sort((a: Policy, b: Policy) => a.rank - b.rank)
          .map((item: Policy) => (
            <div
              key={item.code}
              data-testid="itemList"
              className="flex w-full py-1.5 text-left relative hover:bg-primary-50 hover:cursor-pointer"
              onClick={() => onClick(item.code)}
              style={
                violations?.has(item.code)
                  ? { backgroundColor: 'var(--primary-50)' }
                  : {}
              }
            >
              {violations?.has(item.code) ? (
                <div className="bg-cta flex-shrink-0 w-6 h-6 rounded-lg text-white flex items-center justify-center mr-2 ml-[12px]">
                  <FaCheck />
                </div>
              ) : (
                getItemRank(item.rank)
              )}
              <span className="mr-2 max-w-[60%]">{item.name}</span>
              <Button
                onClick={(e) => onSelectPolicyInfo(e, item.code)}
                hiddenTitle="Policy description reminder"
                style="self-center right-1 absolute hover:bg-transparent"
              >
                <FaInfoCircle size="20" />
              </Button>
              <Dialog
                show={selectedDialog === item.code}
                close={() => setSelectedDialog('')}
                dialogStyle="w-1/3 bg-custom-bg"
              >
                <h2 className="border-b border-lightgray pb-1">
                  {item.name} - {item.code}
                </h2>
                <div
                  id="policy"
                  className="my-3 p-3 prose max-w-none text-dark-text"
                  dangerouslySetInnerHTML={{
                    __html: marked.parse(item?.description)
                  }}
                />
              </Dialog>
            </div>
          ))}
      </div>
    </div>
  );
};

type SliderProp = {
  fields: string[];
  multiPolicySelection: boolean;
  handleClick: (e: any) => void;
};

const Slider = ({ fields, multiPolicySelection, handleClick }: SliderProp) => {
  if (fields?.length > 1)
    return (
      <Tooltip content="Policies per Field">
        <label className="relative cursor-pointer ml-2 flex items-center">
          <input
            type="checkbox"
            checked={multiPolicySelection}
            onChange={handleClick}
            className="sr-only peer"
          />
          <div className="w-11 h-6 rounded-xl bg-cta-disabled border-2 border-cta-disabled peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-custom-bg after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-2 after:border-white after:rounded-xl after:h-5 after:w-5 after:transition-all  peer-checked:bg-cta"></div>
        </label>
      </Tooltip>
    );
};

export { PoliciesSelection };
