import { useApiService } from '@api/services';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '@components/shared/Loader';
import { Header } from '@components/shared/Header';
import { FaTable, FaUserCircle } from 'react-icons/fa';
import { Action } from '@api/types';
import { useUser } from '@context/UserProvider';

const Actions = () => {
  const { platforms } = useUser();
  const { useActions } = useApiService();
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid-setting') || '';
  const platform = platforms?.find((p) => p.id.toString() == platformId);

  const { data: actionsList, isLoading, error } = useActions({ platformId });

  if (error) return <div>Error</div>;
  if (isLoading) return <Loader />;

  return (
    <>
      <div className="flex mb-3">
        <Header>
          <div className="flex flex-col pt-2">
            <div className="text-cta font-semibold">{platform?.name}</div>
            <h2 className="mb-3 text-[28px] lg:text-[32px]">Actions</h2>
          </div>
        </Header>
      </div>
      <div className="flex flex-row gap-3 w-full justify-end px-8 py-1">
        <div className="flex gap-1">
          <FaUserCircle className="flex-shrink-0" size={23} />
          <p>Author</p>
        </div>
        <div className="flex gap-1">
          <FaTable className="flex-shrink-0" size={24} />
          <p>Content</p>
        </div>
      </div>
      <div
        className="mb-4 grid gap-4 pt-3 px-8"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))'
        }}
      >
        {actionsList?.length &&
          actionsList?.map((action: Action) => (
            <div
              className="text-text-white flex flex-shrink-0 flex-grow min-w-[320px] flex-col bg-primary-50 p-3 rounded-md"
              key={action.name}
            >
              <div className="flex justify-between">
                <div className="flex-row flex items-center gap-1">
                  <h5 className="line-clamp-1">{action.name}</h5>
                  <div> - </div>
                  <h6>{action.contentDecision ?? action.authorDecision}</h6>
                </div>
                <div className="ml-1">
                  {action.authorDecision ? (
                    <FaUserCircle size={23} />
                  ) : (
                    <FaTable size={22} />
                  )}
                </div>
              </div>
              <div className="text-sm mt-1">
                Complex types:{' '}
                {action.complexTypes
                  ?.map((complexType) => complexType)
                  .join(', ') ?? 'all'}
              </div>
              <div className="text-sm">Hint: {action.hint ?? 'n/a'}</div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Actions;
