import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { Policy as PolicyType } from '@api/types';
import { Button } from '@components/shared/Buttons';
import '@components/style.css';

const Policy = () => {
  const navigate = useNavigate();
  const { platform, policies } = useUser();
  const { policyId = '' } = useParams();
  const [itemSelected, setItemSelected] = useState<string>(policyId);

  const selectPolicy = (code: string) => {
    setItemSelected(code);
    navigate(`${code}?pid=${platform?.id}`);
  };

  useEffect(() => {
    if (policies?.length && !policyId) {
      selectPolicy(policies[0].code);
    }
  }, [policies]);

  if (!policies?.length)
    return (
      <div className="displayCenter relative top-9">
        <h2>No Policies found. Please select another Platform</h2>
      </div>
    );

  return (
    <div className="flex h-screen bg-custom-bg">
      <div
        className="border-r-[1px] border-neutral-300 pt-[21px] overflow-y-auto"
        style={{ flex: '0 0 276px' }}
      >
        <div>
          {policies?.map((item: PolicyType) => (
            <div
              key={item.code}
              className={`${
                itemSelected === item?.code ? 'border-cta-hover' : 'border-text'
              } border-l-8`}
            >
              <Button
                style={`text-lg py-4 px-5 w-full text-left hover:bg-custom-bg hover:text-cta ${
                  itemSelected === item?.code && 'text-cta font-bold'
                }`}
                key={item.code}
                onClick={() => selectPolicy(item?.code)}
              >
                {item.name}
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div className="overflow-auto w-full mb-8">
        <Outlet />
      </div>
    </div>
  );
};

export default Policy;
