import { useEffect } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { assignHotkey } from '@components/Review/utils';
import { Button } from '@components/shared/Buttons';
import { Action } from '@api/types';

const Decision = ({ availableActions, setSelectedAction }: any) => {
  const { enableScope, disableScope } = useHotkeysContext();

  useEffect(() => {
    enableScope('actions');
    return () => {
      disableScope('actions');
    };
  }, []);

  if (!availableActions?.length) return null;

  availableActions?.forEach((x: Action, index: number) =>
    assignHotkey((++index).toString(), setSelectedAction, x, 'actions')
  );

  assignHotkey('esc', setSelectedAction, '', 'actions');

  return (
    <div className="relative mt-3 before:absolute before:ml-[0.75rem] before:h-full before:border-l-[2px] before:border-dotted before:border-white before:bg-cta">
      <div className="relative">
        <div className="flex gap-0.5 flex-col">
          {availableActions.map((action: Action, index: number) => (
            <Button
              key={action?.name}
              style="ml-6 flex gap-2 justify-start w-full py-1.5 items-center hover:bg-primary-50 hover:cursor-pointer"
              onClick={() => setSelectedAction(action)}
              hiddenTitle={`${action?.name} decision selected`}
            >
              <kbd>{++index}</kbd> {action?.name}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Decision };
