import { ReactElement } from 'react';
import { useUser } from '@context/UserProvider';
import { Property, DomainObject } from '@api/types';
import { ContentImage } from '@components/shared/Content/ContentImage';
import { AudioContent } from '@components/shared/Content/Audio';
import { VideoPlayer } from '@components/shared/Content/Video';
import sanitizeHtml from 'sanitize-html';
import { ContentTypes, IsArrayProp, ContentProps } from '@typeDef/Shared';
import { Translate } from '@components/shared/Content/Translate';
import { HiOutlineDocumentRemove } from 'react-icons/hi';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { TextContent } from '@components/shared/Content/TextContent';

const Item = ({ content }: { content: ReactElement }) => (
  <div className="p-2">{content}</div>
);

const cleanHtml = (html: string) =>
  sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img'])
  });

const isArray = ({ val, callback }: IsArrayProp) =>
  Array.isArray(val) ? (
    val.map((v: string) => <Item key={v} content={callback(v)} />)
  ) : (
    <Item content={callback(val)} />
  );

const fieldToHTMLmapping = (width: string, isSelectable: boolean) =>
  ({
    Text: (val: string | string[]) =>
      isArray({
        val,
        callback: (val: any) => {
          if (typeof val === 'object') {
            return <Translate val={val} />;
          } else {
            return <TextContent val={val} isSelectable={isSelectable} />;
          }
        }
      }),
    Image: (val: string | string[]) =>
      isArray({
        val,
        callback: (val: string) => (
          <ContentImage key={val} content={val} width={width} />
        )
      }),
    Video: (val: string | string[]) =>
      isArray({
        val,
        callback: (val: string) => (
          <VideoPlayer key={val} width={width} url={val} />
        )
      }),
    WYSIWYG: (val: string | string[]) =>
      isArray({
        val,
        callback: (val: string) => (
          <div key={val} dangerouslySetInnerHTML={{ __html: cleanHtml(val) }} />
        )
      }),
    Uri: (val: string | string[]) =>
      isArray({
        val,
        callback: (val: string) => (
          <div className="text-left" key={val}>
            {val}
          </div>
        )
      }),
    Audio: (val: string | string[]) =>
      isArray({
        val,
        callback: (val: string) => <AudioContent key={val} val={val} />
      })
  }) as ContentTypes;

const Content = ({
  fields,
  type,
  selectedProperty,
  setSelectedProperty
}: ContentProps) => {
  const { domainObjects } = useUser();
  const domainObject = domainObjects?.filter(
    (x: DomainObject) => x.type === type
  );

  if (!type) return null;
  if (!fields || !domainObject?.length)
    return (
      <div className="text-center my-4">
        <HiOutlineDocumentRemove size={40} />
        <h5 className="mt-2">No contents to display</h5>
      </div>
    );

  const { properties } = domainObject[0] as DomainObject;

  const availableProperties = properties.filter(
    (property: Property) => fields[property.jsonPath]
  );

  const imgCount = availableProperties.filter(
    ({ type }) => type === 'Image' || type === 'Video'
  );

  const isSelectable = selectedProperty && setSelectedProperty;

  const list = availableProperties.map((property: Property) => {
    const val = fields[property.jsonPath];
    const width = () => {
      if (property.type == 'Video') {
        return '100%';
      } else {
        return imgCount?.length > 1 ? '300px' : '400px';
      }
    };
    if (val) {
      return (
        <Tooltip content={property.jsonPath} delay={1} key={val.toString()}>
          <button
            className={`my-1 w-full contentOverlay justify-center ${
              property.jsonPath == selectedProperty ? 'active' : ''
            } ${
              property.type == 'Image' || property.type == 'Video'
                ? 'flex'
                : 'text-center'
            }`}
            onMouseEnter={() => setSelectedProperty?.(property.jsonPath)}
            onClick={(e) => e.stopPropagation()}
          >
            {fieldToHTMLmapping(width(), isSelectable)[property.type](
              val.toString()
            )}
          </button>
        </Tooltip>
      );
    }
  });
  return <>{list}</>;
};

export { Content };
