import {
  ManualEvaluation,
  Policy,
  FieldViolation,
  ContentDecision,
  Action
} from '@api/types';
import { useHotkeys } from 'react-hotkeys-hook';
import { EvaluationExt, ViolationPayload } from '@typeDef/Review';

type GroupedData = Record<string, any[]>;

const groupBy = <T extends Record<string, any>>(
  arr: T[],
  key: keyof T,
  sortOrder: string[]
): GroupedData => {
  const groupedData: GroupedData = {};

  const remainingKeys = new Set(arr.map((obj) => obj[key]));

  sortOrder.concat(Array.from(remainingKeys)).forEach((groupKey) => {
    const groupArray = arr.filter((obj) => obj[key] === groupKey);
    if (groupArray.length > 0) {
      groupedData[groupKey] = groupArray;
    }
  });

  return groupedData;
};

const sortingArr = [
  't2-escalation',
  'sensitive-high-priority-content',
  'community-guideline-violations',
  'brand-escalation'
];
const getGroupedQueues = (queues: any) =>
  groupBy(queues, 'bucketLabel', sortingArr);

const assignHotkey = (
  key: string,
  callback: (data: any) => void,
  data: Action | string | boolean,
  scope: string
) => useHotkeys(key, () => callback(data), { scopes: scope });

const groupsBy = (arr: any, sortBy: string) =>
  arr.reduce((acc: any, obj: any) => {
    const key = obj[sortBy];
    const curGroup = acc[key] ?? [];
    return { ...acc, [key]: [...curGroup, obj] };
  }, {});

const getEvaluations = (
  evaluations?: EvaluationExt[],
  violations?: any,
  manualEvaluations?: ManualEvaluation[]
) => {
  if (!evaluations?.length) return null;

  const annotatedEvaluations = getAnnotations(manualEvaluations, evaluations);
  const structuredEvaluations = getViolations(violations, annotatedEvaluations);

  return structuredEvaluations;
};

const getViolations = (violations: any, evaluations: EvaluationExt[]) => {
  const sortedResults = (toSrt: any) => {
    return toSrt
      ?.filter((ev: EvaluationExt) => ev?.label && ev?.score >= 0.01)
      ?.sort((a: any, b: any) => b?.score - a?.score);
  };

  const violating = violations.map((v: any) => ({
    policy: v.policy,
    evaluation: sortedResults(v?.evaluations?.map((e: any) => evaluations[e]))
  }));

  const nonViolating = [
    {
      policy: 'Non violating',
      evaluation: sortedResults(
        evaluations?.filter((ev) =>
          violating.every(
            (ac: any) =>
              !ac?.evaluation?.some(
                (a: any) =>
                  a?.field === ev?.field &&
                  a?.label === ev?.label &&
                  a?.strategy === ev?.strategy
              )
          )
        )
      )
    }
  ];
  return [...violating, ...nonViolating];
};

const getAnnotations = (
  manualEvaluations?: ManualEvaluation[],
  evaluations?: EvaluationExt[]
) => {
  if (!manualEvaluations) return evaluations;

  const flattenedManualEvaluations = manualEvaluations
    ?.map((x: ManualEvaluation) => x.evaluations)
    .flat()
    .reverse();

  const evalArray: any = [];

  evaluations?.forEach((m: any) => {
    const matchingEvaluation = flattenedManualEvaluations?.find(
      (e: any) =>
        e?.field === m?.field &&
        e?.label === m?.label &&
        e?.strategy === m?.strategy
    );

    if (matchingEvaluation) {
      evalArray.push({ ...m, annotation: matchingEvaluation?.score });
    } else {
      evalArray.push(m);
    }
  });

  return evalArray;
};

const getValueFromList =
  (list: any, keyToMatch: string, keyToReturn: string | number) =>
  (val?: string | number) =>
    list?.find((m: any) => m[keyToMatch] === val)?.[keyToReturn] || val;

const getViolationList = (payload: any, policies?: Policy[]) =>
  payload.reduce((acc: any, curr: FieldViolation) => {
    const { field, policy } = curr;

    const policyInfo = policies?.find((p: Policy) => p.code === policy);

    const policyName = policyInfo ? policyInfo.name : policy;

    const existingItem = acc.find((obj: any) => obj.field === field);

    existingItem
      ? existingItem.policies.push(policyName)
      : acc.push({
          field: field === 'undefined' ? '' : field,
          policies: [policyName]
        });

    return acc;
  }, []);

const hasSamePolicyViolations = (
  checkedDecision: ContentDecision,
  payload: ViolationPayload
) => {
  const { violations, fieldViolations } = checkedDecision;
  if (violations && !fieldViolations) {
    const selected = payload.violations
      .map((p: FieldViolation) => p.policy)
      .sort();
    return JSON.stringify(selected) === JSON.stringify(violations.sort());
  }

  if (fieldViolations) {
    const policiesLength =
      payload.violations?.length === fieldViolations?.length;

    if (policiesLength) {
      const filteredList: FieldViolation[] = [];

      fieldViolations.forEach((pol: FieldViolation) => {
        payload.violations.forEach((x) => {
          if (x.policy === pol.policy && x.field === pol.field) {
            filteredList.push(pol);
          }
        });
      });

      return filteredList.length === fieldViolations.length;
    }
    return false;
  }
  if (!payload?.violations?.length) return true;

  return false;
};

const findCommonActions = (arraysOfActions: any) => {
  if (!arraysOfActions.length) return [];

  const firstArray = arraysOfActions[0];

  return firstArray.filter((action: string) =>
    arraysOfActions.every((array: string[]) => array.includes(action))
  );
};

export {
  getGroupedQueues,
  assignHotkey,
  getEvaluations,
  groupsBy,
  getValueFromList,
  getViolationList,
  hasSamePolicyViolations,
  getAnnotations,
  getViolations,
  findCommonActions
};
