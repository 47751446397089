import { SummaryMetrics } from '@components/Dashboard/SummaryMetrics';
import { EventChart } from '@components/Dashboard/EventChart';
import { ReviewChart } from '@components/Dashboard/ReviewChart';
import { useDate } from '@context/DateProvider';
import { DailySummary } from '@components/Dashboard/DailySummary';
import { useUser } from '@context/UserProvider';
import { useQueues } from '@context/QueuesProvider';
import { Moderator, QueueInfo } from '@api/types';

const DashboardOverview = () => {
  const { startDate, endDate } = useDate();
  const { moderators } = useUser();
  const { queues } = useQueues();

  if (!queues || !moderators) return null;

  return (
    <div>
      <SummaryMetrics
        durationStart={startDate}
        durationEnd={endDate}
        queueIds={queues.map((x: QueueInfo) => x.id.toString())}
        moderatorIds={moderators.map((x: Moderator) => x.id.toString())}
      />
      <div className="dashboardCharts flex-col mlg:flex-row gap-[20px] mlg:gap-0 w-full">
        <EventChart start={startDate} end={endDate} />
        <ReviewChart
          start={startDate}
          end={endDate}
          queueIds={queues.map((x: QueueInfo) => x.id.toString())}
          moderatorIds={moderators.map((x: Moderator) => x.id.toString())}
        />
      </div>
      <DailySummary
        durationStart={startDate}
        durationEnd={endDate}
        queueIds={queues.map((x: QueueInfo) => x.id.toString())}
        moderatorIds={moderators.map((x: Moderator) => x.id.toString())}
      />
    </div>
  );
};

export default DashboardOverview;
