import { ReactNode } from 'react';
import { IoMdClose } from 'react-icons/io';

const Tray = ({
  show,
  close,
  children
}: {
  show: boolean;
  close: () => void;
  children: ReactNode;
}) => {
  if (!show) return null;

  return (
    <div className="flex z-40 absolute w-screen top-0 bg-black/40 h-full left-0">
      <button
        className="bg-gray/10 min-w-[50%] lg:min-w-[65%] h-full"
        onClick={close}
      ></button>
      <div className="bg-custom-bg overflow-auto border-l h-full w-full pl-4">
        <button className="flex w-full p-2 justify-end">
          <IoMdClose
            size="30"
            color="gray"
            onClick={close}
            className="roundedCircle stroke-[30px] bg-custom-bg p-1 cursor-pointer text-black"
          />
        </button>
        <div className="pr-2">{children}</div>
      </div>
    </div>
  );
};

export { Tray };
