import { useApiClient } from '@hooks/useApiClient';
import { useMutation } from '@tanstack/react-query';
import { useUser } from '@context/UserProvider';
import { skipReviewPayload } from '@typeDef/Review';
import {
  OrganizationMemberInvite,
  WebhookTypes,
  ReportRequest,
  CreateInquiryRequest,
  CreateInquiryFromSearchRequest,
  CreateManualEvaluationRequest,
  PolicyRule,
  Policy,
  CreateQualityCheck,
  IngestContentRequest,
  IngestBulkContentRequest,
  SearchResultResponse,
  SearchResultCountsResponse,
  OrganizationMemberUpdate,
  CreateOrganizationRequest,
  CreateContentDecisionRequest,
  CreateAuthorDecisionRequest
} from '@api/types';
import { SearchDocumentsParam } from '@typeDef/Apis';

const useMutationApi = () => {
  const apiClient = useApiClient();
  const { platform } = useUser();

  const postReviewContent = useMutation({
    mutationFn: (payload: CreateContentDecisionRequest) => {
      return apiClient.post(
        `/review/platforms/${platform?.id}/content-cases/decision`,
        payload
      );
    }
  });

  const postAuthorDecision = useMutation({
    mutationFn: (payload: CreateAuthorDecisionRequest) => {
      return apiClient.post(
        `/review/platforms/${platform?.id}/authors/decision`,
        payload
      );
    }
  });

  const postReviewQA = useMutation({
    mutationFn: (payload: CreateQualityCheck) => {
      return apiClient.post(
        `review/platforms/${platform?.id}/quality/checks`,
        payload
      );
    }
  });

  const postSkipContent = useMutation({
    mutationFn: ({ complexType, contentId }: skipReviewPayload) => {
      return apiClient.post(
        `/review/platforms/${platform?.id}/content-cases/skip`,
        {
          complexType,
          contentId
        }
      );
    }
  });

  const postInvitation = useMutation({
    mutationFn: (payload: OrganizationMemberInvite) =>
      apiClient.post(`/organizations/members/invite`, payload)
  });

  const putWebhook = useMutation({
    mutationFn: ({
      platformId,
      url,
      types
    }: {
      platformId: string;
      url?: string | null;
      types?: WebhookTypes | null;
    }) =>
      apiClient.put(`platforms/${platformId}/outbound/webhook`, {
        url,
        types
      })
  });

  const putSqs = useMutation({
    mutationFn: ({
      platformId,
      sqsUrl,
      roleArn,
      types
    }: {
      platformId: string;
      sqsUrl?: string | null;
      roleArn?: string | null;
      types?: WebhookTypes | null;
    }) =>
      apiClient.put(`platforms/${platformId}/outbound/sqs`, {
        sqsUrl,
        roleArn,
        types
      })
  });

  const postCommunityReport = useMutation({
    mutationFn: (payload: ReportRequest) =>
      apiClient.post(`platforms/${platform?.id}/community/report`, payload)
  });

  const postInquiries = useMutation({
    mutationFn: (payload: CreateInquiryRequest) =>
      apiClient.post(`/review/platforms/${platform?.id}/inquiries`, payload)
  });

  const searchInquiry = useMutation({
    mutationFn: (payload: CreateInquiryFromSearchRequest) =>
      apiClient.post(
        `/review/platforms/${platform?.id}/inquiries/from-search`,
        payload
      )
  });

  const postAnnotation = useMutation({
    mutationFn: (payload: CreateManualEvaluationRequest) =>
      apiClient.post(
        `/review/platforms/${platform?.id}/evaluations/manual`,
        payload
      )
  });

  const useSearchTimeCounts = useMutation({
    mutationFn: async (payload: SearchDocumentsParam) => {
      const { data } = await apiClient.get<SearchResultCountsResponse>(
        `/review/platforms/${platform?.id}/search/counts`,
        { params: payload }
      );
      return data;
    }
  });

  const useSearchDocument = useMutation({
    mutationFn: async (payload: SearchDocumentsParam) => {
      const { data } = await apiClient.get<SearchResultResponse>(
        `/review/platforms/${platform?.id}/search`,
        { params: payload }
      );
      return data;
    }
  });

  const putPolicyRule = useMutation({
    mutationFn: ({ rules, code }: { rules: PolicyRule[]; code: string }) =>
      apiClient.put(`/platforms/${platform?.id}/policies/${code}/rules`, {
        rules
      })
  });

  const getByPolicyCode = useMutation({
    mutationFn: async ({ code }: any) => {
      const { data } = await apiClient.get<Policy>(
        `/platforms/${platform?.id}/policies/${code}`
      );
      return data;
    }
  });

  const postDeleteApikey = useMutation({
    mutationFn: async (apikeyId: string) => {
      const { data } = await apiClient.delete<string>(
        `/platforms/${platform?.id}/api-keys/${apikeyId}`
      );
      return data;
    }
  });

  const postContent = useMutation({
    mutationFn: (payload: IngestContentRequest) => {
      return apiClient.post(
        `platforms/${platform?.id}/analysis/content`,
        payload
      );
    }
  });

  const postContentInBulk = useMutation({
    mutationFn: (payload: IngestBulkContentRequest) => {
      return apiClient.post(
        `platforms/${platform?.id}/analysis/content/bulk`,
        payload
      );
    }
  });
  const postTransparencyKey = useMutation({
    mutationFn: ({
      platformId,
      token,
      sandbox
    }: {
      platformId: number;
      token?: string;
      sandbox?: boolean;
    }) => {
      return apiClient.put(`/platforms/${platformId}/transparency/token`, {
        token,
        sandbox
      });
    }
  });

  const editStrategy = useMutation({
    mutationFn: ({
      LabelKeywords,
      strategy_id
    }: {
      LabelKeywords?: any;
      strategy_id?: number;
    }) =>
      apiClient.put(
        `organizations/strategies/keywords/${strategy_id}`,
        LabelKeywords
      )
  });

  const useUpdateMember = useMutation({
    mutationFn: ({ id, role, enabled }: OrganizationMemberUpdate) =>
      apiClient.post(`/organizations/members/update`, {
        id,
        role,
        enabled
      })
  });

  const postNewOrganization = useMutation({
    mutationFn: (payload: CreateOrganizationRequest) =>
      apiClient.post(`/organizations`, payload)
  });

  const postEmailNotification = useMutation({
    mutationFn: ({ payload, platformId }: any) =>
      apiClient.put(
        `/platforms/${platformId}/outbound/email-notifications`,
        payload
      )
  });
  return {
    postReviewContent,
    postAuthorDecision,
    postReviewQA,
    postSkipContent,
    postAnnotation,
    postInvitation,
    putWebhook,
    putSqs,
    postCommunityReport,
    postInquiries,
    searchInquiry,
    useSearchDocument,
    useSearchTimeCounts,
    putPolicyRule,
    getByPolicyCode,
    postDeleteApikey,
    postContent,
    postContentInBulk,
    postTransparencyKey,
    editStrategy,
    useUpdateMember,
    postNewOrganization,
    postEmailNotification
  };
};

export { useMutationApi };
