import {
  ContentCase,
  Incident,
  IncidentStatus as IncidentStatusType,
  Content as ContentType,
  FieldViolation
} from '@api/types';
import { useUser } from '@context/UserProvider';
import { useQueues } from '@context/QueuesProvider';
import { DurationUnit, formatDuration, getLocaleTime } from '@utils/date';
import { DateTime } from 'luxon';
import { MdOutlinePolicy } from 'react-icons/md';
import { RxAvatar } from 'react-icons/rx';
import { useState } from 'react';
import { getEvaluations, getValueFromList } from '@components/Review/utils';
import { Content } from '@components/shared/Content';
import { ContentFields } from '@components/shared/Content/Fields';
import { PotentialViolations } from '@components/Review/Evaluations/PotentialViolations';
import { DropdownButton } from '@components/shared/Dropdown';
import { IncidentStatus } from '@components/shared/IncidentStatus';
import { HistoryTabs } from '@components/Review/Content/HistoryTabs';

const Entry = ({
  entry,
  value
}: {
  entry: string;
  value?: string | string[] | number | null;
}) => {
  if (!value) return null;
  return (
    <div className="flex mt-2">
      <span className="font-semibold mr-2">{entry}</span>
      <div className="break-all">{value}</div>
    </div>
  );
};

const ViolationList = ({ violations }: { violations: string[] }) => {
  if (!violations.length) return null;

  return (
    <ul className="m-2">
      {violations.map((v: string) => (
        <li key={v} className="break-all font-light">
          {v}
        </li>
      ))}
    </ul>
  );
};

const Violations = ({
  violations
}: {
  violations?: string[] | { key: Set<string> };
}) => {
  const { policies } = useUser();
  const getPolicy = getValueFromList(policies, 'code', 'name');

  if (!violations) return null;

  if (Array.isArray(violations)) {
    return (
      <div className="flex items-start mt-2">
        <MdOutlinePolicy className="mr-2 mt-1" size="20" />
        {violations.length > 1 ? (
          <ViolationList violations={violations.map(getPolicy)} />
        ) : (
          <div>{getPolicy(violations.join(', '))}</div>
        )}
      </div>
    );
  }
  return (
    <>
      {Object.entries(violations).map(([key, val]) => (
        <div className="font-medium" key={key}>
          <MdOutlinePolicy className="mr-2 mt-1" size="20" />
          {key}
          <ViolationList violations={[...val].map(getPolicy)} />
        </div>
      ))}
    </>
  );
};

const TimelineEntry = ({ x }: any) => {
  const isLatestContentVersion = x.totContents - x.version === 1;
  const [open, setOpen] = useState<boolean>(isLatestContentVersion);
  const [selectedProperty, setSelectedProperty] = useState<string>(
    Object.keys(x.evaluations || {})[0]
  );

  if (!x) return null;

  return (
    <div className="flex space-x-4 mb-[10px]">
      <div className="flex">
        <IncidentStatus
          incident={x}
          style={
            'flex items-center justify-center w-10 h-10 rounded-full bg-custom-bg '
          }
        />
      </div>

      <div className="w-full">
        <div className="mb-2 mr-2 pt-2 font-medium">
          {getLocaleTime(x.date)}
          {x.status && ` - Incident ${x.status}`}
          {!x.status && ` - New content version analysed`}
        </div>
        <div className="bg-custom-bg p-3 rounded-[10px] border-[1.5px] border-lightgray shadow-md w-full">
          <DropdownButton
            onClick={() => setOpen(!open)}
            title={
              <div className="capitalize break-all font-semibold">
                {x.causes?.join(', ') || (
                    <div className="flex">
                      {x.resolution?.join(', ')}
                      <div className="font-normal ml-2">
                        {x.decisionHints?.join(', ')}
                      </div>
                    </div>
                  ) ||
                  x.ct}
              </div>
            }
            open={open}
            buttonStyle="bg-custom-bg pl-0"
          />

          {open && (
            <div className="cursor-default">
              <Violations violations={x.violations} />
              {x.moderator && (
                <div className="flex item-baseline mt-2">
                  <RxAvatar className="mr-2" size="20" />
                  <div className="break-all">{x.moderator}</div>
                </div>
              )}
              <Entry entry="Queue" value={x.queue} />
              <Entry entry="Turnaround time" value={x.tat} />
              {x.notes && <Entry entry="Notes" value={`"${x.notes}"`} />}
              {x.content && (
                <div className="flex flex-col 3xl:flex-row gap-3">
                  <div className="w-full 3xl:w-[55%]">
                    {x.content?.fields ? (
                      <ContentFields
                        fields={x.content.fields}
                        selectedProperty={selectedProperty}
                        setSelectedProperty={setSelectedProperty}
                      />
                    ) : (
                      <Content
                        fields={x.content.body}
                        type={x.ct}
                        selectedProperty={selectedProperty}
                        setSelectedProperty={setSelectedProperty}
                      />
                    )}
                  </div>
                  <div className="w-full 3xl:w-[45%]">
                    <PotentialViolations
                      evaluations={x.evaluations}
                      selectedProperty={selectedProperty}
                      setSelectedProperty={setSelectedProperty}
                    />
                  </div>
                </div>
              )}
              {x.causes?.length && (
                <HistoryTabs
                  inquiries={x?.inquiries}
                  reports={x?.communityReports}
                  appeals={x?.appeals}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getViolations = (contentCase: ContentCase, x: Incident) => {
  if (
    !x.contentDecisions ||
    !contentCase.contentDecisions ||
    !contentCase.contentDecisions[x.contentDecisions[0]].fieldViolations
  )
    return x.violations;

  const enrichedData: { [key: string]: Set<string> } = {};
  contentCase.contentDecisions[x.contentDecisions[0]].fieldViolations?.map(
    (x: FieldViolation) => {
      const key = x.field;
      if (enrichedData[key]) {
        return enrichedData[key].add(x.policy);
      }
      enrichedData[x.field] = new Set();
      enrichedData[x.field].add(x.policy);
    }
  );
  return enrichedData;
};

const EventTimeline = ({ contentCase }: { contentCase?: ContentCase }) => {
  const { moderators, platform } = useUser();
  const { queues } = useQueues();

  if (!contentCase) return null;

  const getModerator = getValueFromList(moderators, 'id', 'email');
  const getQueue = getValueFromList(queues, 'id', 'name');
  const getTime = (date: string) =>
    DateTime.fromISO(date).setZone(platform?.timezone);
  const timelineList: any = [];

  const addOpenIncident = (x: Incident) => {
    return timelineList.push({
      status: IncidentStatusType.OPEN,
      date: getTime(x.openTs),
      causes: x.causes,
      violations: x.potentialViolations,
      inquiries: x?.inquiries?.map((loc) => contentCase?.inquiries?.[loc]),
      communityReports: x?.communityReports?.map(
        (loc) => contentCase?.communityReports?.[loc]
      ),
      appeals: x?.appeals?.map((loc) => contentCase?.appeals?.[loc])
    });
  };

  const addClosedIncident = (i: Incident, contentCase: ContentCase) => {
    return timelineList.push({
      status: IncidentStatusType.CLOSED,
      date: getTime(i.closedTs || ''),
      violations: getViolations(contentCase, i),
      resolution: i.resolutions,
      decisionHints: i.contentDecisions?.map(
        (x: number) => contentCase?.contentDecisions?.[x]?.hint
      ),
      queue: getQueue(i.queueId),
      moderator: getModerator(i.moderator),
      tat: formatDuration(i.tat, DurationUnit.Second),
      notes:
        contentCase?.contentDecisions?.[i?.contentDecisions?.[0] || 0]?.notes ??
        ''
    });
  };

  if (contentCase.incidents) {
    [...contentCase.incidents].reverse().forEach((x: Incident) => {
      return x.status === IncidentStatusType.OPEN
        ? addOpenIncident(x)
        : (addClosedIncident(x, contentCase), addOpenIncident(x));
    });
  }

  contentCase?.contents?.map((x: ContentType, i) => {
    timelineList.push({
      ct: contentCase.ct,
      date: getTime(x.ts),
      content: x,
      evaluations: getEvaluations(
        x?.evaluations,
        x?.violations || [],
        contentCase.manualEvaluations
      ),
      version: i,
      totContents: contentCase?.contents?.length
    });
  });

  const sortedList = [...timelineList].sort((a, b) => b.date - a.date);

  return (
    <div className="relative mt-4 before:absolute before:ml-[1.25rem] before:h-full before:w-[1.5px] before:bg-gray">
      <div className="relative">
        {sortedList?.map((x: any) => (
          <TimelineEntry
            key={JSON.stringify(x)}
            x={x}
            contentCase={contentCase}
          />
        ))}
      </div>
    </div>
  );
};

export { EventTimeline };
