import { useState, useEffect, ChangeEvent } from 'react';
import { Dialog } from '@components/shared/Dialog';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { FaChevronUp, FaTrashAlt } from 'react-icons/fa';
import { FaChevronDown, FaPlus } from 'react-icons/fa6';
import { KeywordChip } from '@components/Settings/Strategies/KeywordChip';
import { useUser } from '@context/UserProvider';

const processInput = (text: string) =>
  text.includes(',')
    ? text.split(',').map((word) => word.trim())
    : [text.trim()];

const StrategyEdit = ({
  label,
  list,
  isPending,
  isSuccess,
  strategyPayload,
  onSubmit,
  sendEvent
}: {
  label?: string;
  list?: string[];
  isPending: boolean;
  isSuccess: boolean;
  strategyPayload: any;
  onSubmit: any;
  sendEvent: any;
}) => {
  const [open, setOpen] = useState<boolean>();
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<string>('');
  const [keywordInput, setKeywordInput] = useState<string>('');
  const [keywordsToRemove, setKeywordsToRemove] = useState<Set<string>>(
    new Set()
  );
  const { user } = useUser();

  const addKeywords = (keywords: Set<string>, label: string) => {
    const keywordValues = Array.from(keywords);
    if (keywordValues?.length) {
      const entry = strategyPayload?.get(label);
      keywordValues.map((keyword: string) => {
        !entry.has(keyword) ? entry?.add(keyword) : entry?.delete(keyword);
      });
    }
    sendEvent('clickEvent', { title: `Added keywords to ${label} label` });
    onSubmit();
  };

  const deleteLabel = (label: string) => {
    if (strategyPayload?.has(label)) {
      strategyPayload.delete(label);
    }
    sendEvent('clickEvent', { title: `deleted ${label} label` });
    onSubmit();
  };

  useHotkeys('enter', () => handleAddKeyword);

  useEffect(() => {
    if (isSuccess) {
      setShowDelete(false);
      setKeywordsToRemove(new Set());
    }
  }, [isSuccess]);

  if (!label) return null;

  const handleDeleteLabel = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setSelectedLabel(label);
    setShowDelete(true);
  };

  const handleSelected = (keyword: string) => {
    setKeywordsToRemove((prev) => {
      const list = new Set(prev);
      list.has(keyword) ? list.delete(keyword) : list.add(keyword);
      return list;
    });
  };

  const handleDelete = () => {
    if (
      selectedLabel ||
      (list && list?.length <= 1) ||
      list?.length === keywordsToRemove.size
    ) {
      deleteLabel(label);
    } else {
      !!keywordsToRemove.size && addKeywords(keywordsToRemove, label);
    }
  };

  const handleAddKeyword = async () => {
    const keyword = processInput(keywordInput);
    keywordInput.length > 1 && addKeywords(new Set(keyword), label);
    setKeywordInput('');
  };

  return (
    <div
      className={`h-min ${list ? 'cursor-pointer' : ''} flex items-center flex-grow flex-col ${open ? 'row-span-8' : ''}`}
      key={`${label}--edit`}
    >
      <div
        className={`flex flex-row justify-between border-neutral-300 px-3 py-2 w-full ${open ? '' : 'border-b'}`}
        onClick={() => list && setOpen(!open)}
        data-testid={`${label}--edit`}
      >
        <div
          className={`line-clamp-1 font-medium ${open ? 'line-clamp-2' : ''}`}
        >
          {label}
        </div>
        <div className="flex flex-row gap-3 relative">
          {!!user?.isUserAdmin && (
            <Button
              onClick={(e) => handleDeleteLabel(e)}
              style="border-2 border-error rounded-[6px] px-1"
            >
              <FaTrashAlt size={15} className="text-error" />
            </Button>
          )}
          {list &&
            (!open ? <FaChevronDown size={15} /> : <FaChevronUp size={15} />)}
        </div>
      </div>
      {open && (
        <div className="pt-2 max-h-[432px] overflow-y-auto flex-wrap flex gap-1 w-full cursor-default">
          {!!user?.isUserAdmin && (
            <div className="flex flex-row gap-2 w-full h-9 mb-1 roundedContainer">
              <input
                className="w-full focus:outline-none bg-custom-bg"
                placeholder="Add keywords..."
                onChange={(e) => setKeywordInput(e.target.value)}
                onKeyDown={({ key }) => key === 'Enter' && handleAddKeyword()}
                value={keywordInput}
              ></input>
              <Button
                onClick={handleAddKeyword}
                style="max-h-min flex justify-center items-center w-10"
              >
                <div>
                  <FaPlus size={21} className="text-cta" />
                </div>
              </Button>
            </div>
          )}
          {list?.map((keyword) => (
            <KeywordChip
              keyword={keyword}
              selected={Array.from(keywordsToRemove)?.includes(keyword)}
              onClick={user?.isUserAdmin ? handleSelected : undefined}
              key={`${keyword}--list`}
            />
          ))}
          {!!keywordsToRemove.size && (
            <Button
              onClick={handleDelete}
              style="px-1 text-sm flex gap-1 items-center underline underline-offset-2"
            >
              <FaTrashAlt size={13} />{' '}
              <span className="text-secondary-300">
                ({keywordsToRemove.size})
              </span>{' '}
              selected
            </Button>
          )}
        </div>
      )}
      <Dialog show={showDelete} close={() => setShowDelete(!showDelete)}>
        <h4 className="mb-3">Delete label</h4>
        <div className="mb-3">{selectedLabel}</div>
        <div className="flex gap-2 justify-end">
          <SubmitButtons
            onClose={() => setShowDelete(false)}
            onSubmit={handleDelete}
            submitLabel="Confirm"
            isLoading={isPending}
          />
        </div>
      </Dialog>
    </div>
  );
};

export { StrategyEdit };
