import { useEffect, useState, ReactNode } from 'react';
import './TooltipStyle.css';

type TooltipProps = {
  content?: string | ReactNode;
  placement?: string;
  children?: any;
  delay?: number;
};

const Tooltip = ({ content, placement, children, delay }: TooltipProps) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay ?? 400);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
      hideTip();
    };
  }, []);

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  if (!content) return children;
  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onClick={hideTip}
    >
      {children}
      {active && (
        <div
          className={`Tooltip-Tip ${placement ?? 'bottom'} h-fit min-h-[28px] text-text`}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export { Tooltip };
