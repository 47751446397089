import { useState } from 'react';
import { Loader } from '@components/shared/Loader';
import { Header } from '@components/shared/Header';
import { Strategy } from '@api/types';
import { Button } from '@components/shared/Buttons';
import { useNavigate } from 'react-router-dom';
import { useApiService } from '@api/services';
import { strategiesIcons } from '@components/Policy/constant';

const Strategies = () => {
  const { useStrategies } = useApiService();
  const { data: strategies, isLoading: isLoadingStrategies } = useStrategies();
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  if (isLoadingStrategies) return <Loader />;

  return (
    <div>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Settings</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">Strategies</h2>
        </div>
      </Header>
      <div className="mt-4 mx-8">
        <div className="flex flex-row items-center justify-between gap-3">
          <input
            className="w-full py-1 p-2 bg-transparent focus:outline-none border border-primary-50 rounded-md"
            type="text"
            value={search}
            placeholder="Search.."
            onChange={(e) => setSearch(e.target.value)}
          ></input>
        </div>
      </div>
      <div
        className="mb-4 grid gap-4 px-8 pt-8"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))'
        }}
      >
        {strategies
          ?.filter((strategy: Strategy) =>
            strategy.name.toLowerCase().includes(search.toLowerCase())
          )
          ?.map((strategy: Strategy) => (
            <Button
              key={strategy?.id}
              onClick={() => navigate(`${strategy?.id}`)}
              style="bg-primary-50 min-w-[320px] h-36 rounded-lg flex items-center flex-grow hover:bg-primary-100 cursor-pointer flex-col px-6 overflow-hidden"
            >
              <div className="h-28 flex flex-col justify-evenly my-3 items-start w-full">
                <div className="flex flex-row justify-between w-full">
                  <img
                    width="30"
                    alt="strategy-logo"
                    className="max-w-[30px]"
                    src={strategiesIcons[strategy.type] || '/favicon-32x32.png'}
                  />
                </div>
                <h6>{strategy?.name}</h6>
                <div className="text-md font-normal">{strategy?.slug}</div>
              </div>
            </Button>
          ))}
      </div>
    </div>
  );
};

export default Strategies;
