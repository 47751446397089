/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum IncidentCause {
    EVALUATION = 'evaluation',
    REPORT = 'report',
    REVIEW = 'review',
    APPEAL = 'appeal',
    INQUIRY = 'inquiry',
}
