import { useApiService } from '@api/services';
import { ModeratorBreakdownProp } from '@typeDef/Dashboard';
import { Table } from '@components/shared/Table';
import { AnalyticsModeratorDetails, AnalyticsQueueDetails } from '@api/types';
import { getFullNameFromEmail, getModeratorEmail } from '@utils/string.jsx';
import { formatDuration } from '@utils/date';

const formatPercentage = (value: number) => (value * 100).toFixed(1) + '%';

const formatOptional = (
  callback: (_: number) => string,
  value?: number
): string => (!value ? 'N/A' : callback(value));

const BreakdownCell = ({ list }: { list: (number | undefined)[] }) => {
  return (
    <div className="flexRow">
      <div className="moderatorBreakdownCell">{list[0]}</div>
      <div className="moderatorBreakdownCellPercentage">
        {formatOptional(formatPercentage, list[1])}
      </div>
    </div>
  );
};

const ModeratorBreakdown = ({
  queueIds,
  queues,
  moderators,
  moderatorIds,
  durationStart,
  durationEnd
}: ModeratorBreakdownProp) => {
  const { useModeratorBreakdownMetrics, useQueueBreakdownMetrics } =
    useApiService();

  const { data: modMetrics, isLoading: isLoadingModerator } =
    useModeratorBreakdownMetrics({
      durationStart,
      durationEnd,
      queueIds,
      moderatorIds
    });

  const { data: queueMetrics, isLoading: isLoadingQueues } =
    useQueueBreakdownMetrics({
      durationStart,
      durationEnd,
      queueIds,
      moderatorIds
    });

  const normalizedQueues = queueMetrics?.queues
    ?.sort((a: any, b: any) => b.reviews - a.reviews)
    .map((item: AnalyticsQueueDetails) => [
      {
        header: 'Queue',
        val: queues?.find((q) => q.id === item.queueId)?.name
      },
      {
        header: 'Reviews',
        val: item?.reviews
      },
      {
        header: 'Enforcements',
        val: (
          <BreakdownCell list={[item?.enforcements, item?.enforcementRate]} />
        )
      },
      {
        header: 'Avg handle time',
        val: formatOptional(formatDuration, item?.aht)
      }
    ]);

  const normalizedModerators = modMetrics?.moderators
    ?.sort((a: any, b: any) => b.reviews - a.reviews)
    .map((item: AnalyticsModeratorDetails) => [
      {
        header: 'Moderators',
        val: getFullNameFromEmail(
          getModeratorEmail(item?.moderatorId, moderators)
        )
      },
      {
        header: 'Reviews',
        val: item?.reviews
      },
      {
        header: 'Enforcements',
        val: (
          <BreakdownCell list={[item?.enforcements, item?.enforcementRate]} />
        )
      },
      {
        header: 'Avg handle time',
        val: formatOptional(formatDuration, item?.aht)
      }
    ]);

  return (
    <div className="flex flex-col xl:flex-row justify-between flex-1 gap-[20px]">
      <div className="flex-auto overflow-hidden rounded-md">
        <div className="moderatorBreakdownTables">
          <Table
            tdStyle="overflow-hidden text-ellipsis h-6 w-fit"
            isLoading={isLoadingQueues}
            list={normalizedQueues}
          />
        </div>
      </div>
      <div className="flex-auto overflow-hidden rounded-md">
        <div className="moderatorBreakdownTables">
          <Table
            isLoading={isLoadingModerator}
            tdStyle="overflow-hidden text-ellipsis h-6 w-fit"
            list={normalizedModerators}
          />
        </div>
      </div>
    </div>
  );
};

export { ModeratorBreakdown };
