import {
  useState,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useEffect
} from 'react';
import { DomainObject, Platform, Moderator, Policy, Action } from '@api/types';
import { useApiClient } from '@hooks/useApiClient';
import { useQuery } from '@tanstack/react-query';
import { UserType, UserContextType } from '@typeDef/User';
import { useSearchParams, useLocation } from 'react-router-dom';
import { getUrlParams } from '@utils/getUrlParams';

interface Props {
  children: ReactNode;
}

export const UserContext = createContext<UserContextType>(undefined!);

const UserProvider = ({ children }: Props) => {
  const location = useLocation();
  const apiClient = useApiClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const storedTheme = localStorage.getItem('theme') || 'light';
  const [theme, setTheme] = useState<string>(storedTheme);

  const [platform, setPlatform] = useState<Platform | null>(null);
  const [domainObject, setDomainObject] = useState<DomainObject | null>(null);

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const { data } = await apiClient.get<UserType>(`/users/me`);
      return {
        ...data,
        isUserAdmin: data.role === 'administrator',
        isRegistered: !!data.role && !!data.email && !!data.org
      };
    }
  });

  useEffect(() => {
    if (theme) {
      localStorage.setItem('theme', theme);
    }
  }, [theme]);

  useEffect(() => {
    if (platform) {
      const params = getUrlParams(location.search);
      const pid = searchParams.get('pid');

      if (pid && pid !== platform?.id.toString()) {
        setSearchParams({ ...params, pid: platform?.id });
      }
      localStorage.setItem('selectedPlatformId', JSON.stringify(platform.id));
    }
  }, [platform]);

  const {
    data: platforms,
    refetch: updatePlatforms,
    isLoading: isLoadingPlatform,
    error: platformError,
    fetchStatus: isPlatformFetchEnabled
  } = useQuery({
    queryKey: ['platforms'],
    queryFn: async () => {
      const { data } = await apiClient.get<Platform[]>(`/platforms/`);
      const storedPlatformId = localStorage.getItem('selectedPlatformId') ?? '';
      const pid = searchParams.get('pid');

      if (data) {
        const platformStored =
          data.find((i) => i.id.toString() === storedPlatformId) || data[0];
        const redirectedPlatform =
          pid && data?.find((p: Platform) => p.id.toString() === pid);

        if (redirectedPlatform) setPlatform(redirectedPlatform);
        if (!redirectedPlatform && platformStored) setPlatform(platformStored);
        if (!redirectedPlatform && !platformStored) setPlatform(data[0]);
      }
      return data;
    },
    enabled: !!user?.isRegistered,
    //Checking platform API updates every 10 minutes
    refetchInterval: 600000
  });

  const {
    data: domainObjects,
    refetch: refetchDomains,
    isLoading: isLoadingDomains
  } = useQuery({
    queryKey: ['domainsObject', platform?.id],
    queryFn: async () => {
      const { data } = await apiClient.get<DomainObject[]>(
        `/platforms/${platform?.id}/domain-objects`
      );
      if (data) {
        setDomainObject(data[0]);
      }
      return data;
    },
    enabled: !!platform?.id
  });

  const { data: moderators } = useQuery({
    queryKey: ['moderators'],
    queryFn: async () => {
      const { data } = await apiClient.get<Moderator[]>(`/moderators/list/all`);
      return data;
    },
    enabled: !!user?.isUserAdmin
  });

  const { data: policies } = useQuery({
    queryKey: ['policies', platform?.id],
    queryFn: async () => {
      const { data } = await apiClient.get<Policy[]>(
        `/platforms/${platform?.id}/policies`
      );
      return data;
    },
    enabled: !!platform?.id
  });

  const { data: actions } = useQuery({
    queryKey: ['actions', platform?.id],
    queryFn: async () => {
      const { data } = await apiClient.get<Action[]>(
        `/platforms/${platform?.id}/actions`
      );
      return data;
    },
    enabled: !!platform?.id
  });

  const value = useMemo(
    () => ({
      platforms,
      isPlatformFetchEnabled,
      isLoadingPlatform,
      platformError,
      platform,
      setPlatform,
      updatePlatforms,
      domainObjects,
      domainObject,
      refetchDomains,
      setDomainObject,
      user,
      moderators,
      isLoadingDomains,
      policies,
      actions,
      theme,
      setTheme
    }),
    [
      platforms,
      isLoadingPlatform,
      platformError,
      platform,
      domainObject,
      domainObjects,
      moderators,
      user,
      isLoadingDomains,
      policies,
      actions,
      theme
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    // Adding custom error
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
