import { useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import Profile from '@containers/Profile';
import ErrorFallback from '@containers/ErrorFallback';
import Explore from '@components/Explore';
import QueuesList from '@components/Queues';
import ReviewContent from '@components/Review/EntryPoints/ReviewContent';
import QueueSettings from '@components/Settings/QueueSettings';
import Queue from '@components/Settings/QueueSettings/Queue';
import Actions from '@components/Settings/Actions';
import TestAndIntegrate from '@components/TestAndIntegrate';
import QueueBulkList from '@components/Queues/BulkList';
import ReviewSpecificEvent from '@components/Review/EntryPoints/ReviewSpecificEvent';
import Policy from '@components/Policy/PolicyItem';
import Dashboard from '@components/Dashboard';
import ProtectedRoute from '@components/shared/ProtectedRoute';
import Policies from '@components/Policy';
import { useUser } from '@context/UserProvider';
import { getUrlParams } from '@utils/getUrlParams';
import SettingPage from '@components/Settings';
import Strategies from '@components/Settings/Strategies';
import Usage from '@components/Settings/Usage';
import { StrategyCard } from '@components/Settings/Strategies/StrategyCard';
import OrganizationMembers from '@components/Settings/Members';
import ApiKeys from '@components/Settings/ApiKeys';
import Webhooks from '@components/Settings/Webhooks';
import Regulations from '@components/Settings/Regulations';
import General from '@components/Settings/General';
import Sqs from '@components/Settings/Sqs';
import Overview from '@components/Dashboard/Overview';
import Workflow from '@components/Dashboard/Workflow';
import Workforce from '@components/Dashboard/Workforce';
import Transparency from '@components/Settings/Transparency';

export default function Navigation() {
  const [, setSearchParams] = useSearchParams();
  const { platform } = useUser();
  const location = useLocation();
  const { pid, ...params } = getUrlParams(location.search);

  useEffect(() => {
    if (!pid && location.pathname !== '/') {
      return setSearchParams({ ...params, pid: platform?.id });
    }
  }, [pid, platform?.id, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/queues" replace />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route index element={<Overview />} />
        <Route path="workflow" element={<Workflow />} />
        <Route path="workforce" element={<Workforce />} />
      </Route>
      <Route path="/explore" element={<Explore />} />
      <Route path="/settings" element={<SettingPage />}>
        <Route path="test" element={<TestAndIntegrate />} />
        <Route path="strategies" element={<Strategies />} />
        <Route path="strategies/:strategyId" element={<StrategyCard />} />
        <Route
          path="members"
          element={
            <ProtectedRoute>
              <OrganizationMembers />
            </ProtectedRoute>
          }
        />
        <Route
          path="usage"
          element={
            <ProtectedRoute>
              <Usage />
            </ProtectedRoute>
          }
        />
        <Route path="general" element={<General />} />
        <Route path="actions" element={<Actions />} />
        <Route path="profile" element={<Profile />} />
        <Route
          path="queues"
          element={
            <ProtectedRoute>
              <QueueSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="queues/:queueId"
          element={
            <ProtectedRoute>
              <Queue />
            </ProtectedRoute>
          }
        />
        <Route
          path="api-keys"
          element={
            <ProtectedRoute>
              <ApiKeys />
            </ProtectedRoute>
          }
        />
        <Route
          path="webhooks"
          element={
            <ProtectedRoute>
              <Webhooks />
            </ProtectedRoute>
          }
        />
        <Route
          path="sqs"
          element={
            <ProtectedRoute>
              <Sqs />
            </ProtectedRoute>
          }
        />
        <Route
          path="regulations"
          element={
            <ProtectedRoute>
              <Regulations />
            </ProtectedRoute>
          }
        />
        <Route
          path="transparency"
          element={
            <ProtectedRoute>
              <Transparency />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="/queues" element={<QueuesList />} />
      <Route path="/queues/bulklist/:queueId" element={<QueueBulkList />} />
      <Route
        path="/queues/:queueId/review/event/:contentId/:ct"
        element={<ReviewSpecificEvent />}
      />
      <Route path="/queues/:queueId/review" element={<ReviewContent />} />
      <Route path="policies" element={<Policies />}>
        <Route path=":policyId" element={<Policy />} />
      </Route>
      <Route path="/error" element={<ErrorFallback />} />
      <Route path="*" element={<ErrorFallback />} />
    </Routes>
  );
}
