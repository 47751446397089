import { Header } from '@components/shared/Header';
import { FaChevronLeft } from 'react-icons/fa6';
import { Button } from '@components/shared/Buttons';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useApiService } from '@api/services';
import { Loader } from '@components/shared/Loader';
import { Table } from '@components/shared/Table';
import { QueueInfo, Criterion, QueueCriteria } from '@api/types';
import { TableItem } from '@typeDef/Shared';

const getCriteriaNormalised = (
  criteria?: QueueCriteria
): TableItem[][] | null => {
  if (!criteria) return null;

  const output: Record<string, { include?: string[]; exclude?: string[] }> = {};
  const keys: ('include' | 'exclude')[] = ['include', 'exclude'];

  keys.forEach((key) => {
    const items = criteria[key];
    if (items) {
      items.forEach(({ op, args }: Criterion) => {
        if (!output[op]) {
          output[op] = {};
        }
        output[op][key] = args?.map(String);
      });
    }
  });

  return Object.entries(output).map(([op, values]) => [
    { header: '', val: op },
    { header: 'Include', val: values?.include?.join(', ') },
    { header: 'Exclude', val: values?.exclude?.join(', ') }
  ]);
};

const Queue = () => {
  const { useQueueDescription, useQueues } = useApiService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { queueId } = useParams();
  const platformId = searchParams.get('pid') || '';
  const platformSection = searchParams.get('pid-setting') || '';
  const { data: queues } = useQueues({
    enabled: false,
    platformId: platformSection
  });

  const { data, isLoading, error } = useQueueDescription({
    queueId,
    platformId: platformSection
  });

  if (isLoading) return <Loader />;
  if (error || !data || !queues) return <div>Please try later</div>;

  const queue = queues?.find((q: QueueInfo) => q.id.toString() === queueId);
  const criteria = getCriteriaNormalised(data?.criteria);

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Queues</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">{queue?.name}</h2>
        </div>
      </Header>
      <div className="p-8 flex gap-8 flex-col">
        <div className="w-full flex gap-5">
          <Button
            style="buttonOutlined"
            hiddenTitle="settingQueueBackBtn"
            onClick={() =>
              navigate(
                `/settings/queues?pid=${platformId}&pid-setting=${platformSection}`
              )
            }
          >
            <FaChevronLeft size={15} />
          </Button>
          <h3>Queue settings</h3>
        </div>
        <div className="flex gap-4 flex-col">
          <div className="font-semibold">Access Control List</div>
          <div className="border-[1px] border-neutral-300 p-2 rounded-md text-neutral-300">
            {data?.userGroups ? (
              <div>{data.userGroups.map((x: string) => x).join(' ')}</div>
            ) : (
              <div>No restrictions</div>
            )}
          </div>
        </div>
        <div className="flex gap-4 flex-col">
          <h3>Criteria</h3>
          {criteria && (
            <div className="border-[1px] border-neutral-300 overflow-auto rounded-md">
              <Table
                list={criteria}
                tbStyle="bg-custom-bg"
                tdStyle="p-4 border-top-neutral-300"
                thStyle="p-4 border-top-neutral-300 text-lg"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Queue;
