import { Table } from '@components/shared/Table';
import { PolicyMetrics, PoliciesMetrics } from '@api/types';
import { Doughnut } from 'react-chartjs-2';
import { getPercentage, getRate } from '@components/Dashboard/Workflow';

const Resolutions = ({
  policiesMetrics
}: {
  policiesMetrics?: PoliciesMetrics;
}) => {
  const normalizedEnforcements = policiesMetrics?.policies?.map(
    (policy: PolicyMetrics) => [
      {
        header: 'Policy',
        val: (
          <div>
            {policy?.policy.code} - {policy?.policy.name}{' '}
          </div>
        )
      },
      {
        header: 'Total resolutions',
        val: policy.closed
      },
      {
        header: 'Automated enforcement',
        val: getPercentage({
          val: policy.enforcedAutomatically,
          reference: policy.closed
        })
      },
      {
        header: 'Manual enforcement',
        val: getPercentage({
          val: policy.enforcedManually,
          reference: policy.closed
        })
      },
      {
        header: 'Dismissal',
        val: getPercentage({ val: policy.dismissed, reference: policy.closed })
      }
    ]
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const resolutionData = {
    labels: [
      'Manual enforcements',
      'Automated enforcements',
      'Manual Dismissal'
    ],
    datasets: [
      {
        data: [
          getRate({
            val: policiesMetrics?.totals?.enforcedManually,
            reference: policiesMetrics?.totals?.closed
          }),
          getRate({
            val: policiesMetrics?.totals?.enforcedAutomatically,
            reference: policiesMetrics?.totals?.closed
          }),
          getRate({
            val: policiesMetrics?.totals?.dismissed,
            reference: policiesMetrics?.totals?.closed
          })
        ],
        backgroundColor: [
          'rgb(99 102 241)',
          'rgb(245 81 105)',
          'rgb(16 185 129)'
        ]
      }
    ]
  };

  return (
    <div className="w-full">
      <div className="text-xl font-semibold mb-6">
        Resolutions by flagged policy
      </div>
      <div className="flex w-full gap-4 h-[19.5rem]">
        <div className="w-1/3 bg-custom-bg rounded-md border-[1px] border-neutral-300 p-6 flex gap-4 flex-col">
          <div className="text-lg font-semibold">Enforcements</div>
          <div className="flex gap-6 justify-between h-full">
            <div className="w-1/2">
              <Doughnut data={resolutionData} options={options} />
            </div>
            <div className="w-1/2 text-sm flex gap-3 flex-col h-full">
              <div>
                <div>Manual enforcements</div>
                <div className="text-cta">
                  {getPercentage({
                    val: policiesMetrics?.totals?.enforcedManually,
                    reference: policiesMetrics?.totals?.closed
                  })}
                </div>
              </div>
              <div>
                <div>Automated enforcements</div>
                <div className="text-secondary-500">
                  {getPercentage({
                    val: policiesMetrics?.totals?.enforcedAutomatically,
                    reference: policiesMetrics?.totals?.closed
                  })}
                </div>
              </div>
              <div>
                <div>Manual dismissal</div>
                <div className="text-success">
                  {getPercentage({
                    val: policiesMetrics?.totals?.dismissed,
                    reference: policiesMetrics?.totals?.closed
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/3 bg-custom-bg rounded-md border-[1px] border-neutral-300 overflow-auto">
          <Table
            tdStyle="overflow-hidden text-ellipsis h-6 w-fit"
            list={normalizedEnforcements}
          />
        </div>
      </div>
    </div>
  );
};

export { Resolutions };
