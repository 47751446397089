/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum IncidentStatusOptions {
    NO_INCIDENT = 'no-incident',
    INCIDENT = 'incident',
    OPEN = 'open',
    CLOSED = 'closed',
}
