import { useEffect } from 'react';
import { useApiService } from '@api/services';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { WebhookConfig } from '@api/types';
import { Loader } from '@components/shared/Loader';
import { SubmitButtons } from '@components/shared/Buttons';
import { Header } from '@components/shared/Header';
import { useUser } from '@context/UserProvider';

const Webhooks = () => {
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid-setting') || '';
  const { useWebhook } = useApiService();
  const { data: webhook, isLoading, error } = useWebhook({ platformId });

  if (isLoading) return <Loader />;
  if (error || !webhook)
    return (
      <div className="text-alert m-3">Error: could not retrieve settings</div>
    );

  return <WebhooksForm webhook={webhook} platformId={platformId} />;
};

type FormProps = {
  webhook: WebhookConfig;
  platformId: string;
};

const WebhooksForm = ({ webhook, platformId }: FormProps) => {
  const queryClient = useQueryClient();
  const { platforms } = useUser();
  const platform = platforms?.find((p) => p.id.toString() == platformId);
  const { register, handleSubmit, reset } = useForm({ defaultValues: webhook });
  const { putWebhook } = useApiService();

  useEffect(() => {
    reset(webhook);
  }, [webhook]);

  const updateWebhook = (data: WebhookConfig) => {
    putWebhook.mutate(
      {
        platformId,
        ...data
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: ['useWebhook', platformId]
          });
        },
        onError: (err) => {
          console.log('error', err);
        }
      }
    );
  };

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">{platform?.name}</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">
            Webhooks Integration
          </h2>
        </div>
      </Header>
      <form
        className="flex gap-4 flex-col p-8"
        onSubmit={handleSubmit(updateWebhook)}
      >
        <div className="flex gap-3">
          <div className="font-bold">Secret:</div>
          <p className="break-all">{webhook.secret}</p>
        </div>
        <div className="flex gap-2 items-center">
          <div className="font-bold">URL:</div>
          <input
            className="roundedContainer pl-2 pb-2 w-full"
            {...register('url')}
          />
        </div>
        <div>
          <div className="font-bold">Triggers</div>
          <div className="mt-1">
            <div className="indent-4">
              <input type="checkbox" {...register('types.decision')} />
              <label className="ml-2">Decision Taken</label>
            </div>
            <div className="indent-4">
              <input type="checkbox" {...register('types.incidentClosed')} />
              <label className="ml-2">Incident Closed</label>
            </div>
            <div className="indent-4">
              <input
                type="checkbox"
                className="text-cta p-2"
                {...register('types.analysedContent')}
              />
              <label className="ml-2">Content Analysed</label>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <SubmitButtons
            onSubmit={() => handleSubmit(updateWebhook)}
            submitLabel="Save"
            isLoading={putWebhook?.isPending}
            submitStyle="font-bold py-2"
          />
        </div>
      </form>
    </>
  );
};

export default Webhooks;
