import { Loader } from '@components/shared/Loader';
import { Doughnut } from 'react-chartjs-2';
import { Header } from '@components/shared/Header';
import { useRef, useState } from 'react';
import { useApiService } from '@api/services';
import { addCommasToNumber } from '@utils/string';
import { useUser } from '@context/UserProvider';

const CustomLegend = ({
  labels,
  backgroundColors,
  hiddenStates,
  handleClick
}: any) => {
  const legendItems = labels?.map((label: string, index: number) => {
    return (
      <div
        key={index}
        className={`custom-legend-item cursor-pointer ${hiddenStates[index] ? 'line-through' : ''}`}
        onClick={() => handleClick(index)}
      >
        <span
          className="custom-legend-box"
          style={{ backgroundColor: backgroundColors[index] }}
        ></span>
        <span className="leading-4 line-clamp-2">{label}</span>
      </div>
    );
  });

  return <div className="flex flex-wrap gap-2 mt-4">{legendItems}</div>;
};

const Usage = () => {
  const { useUsageMetrics } = useApiService();
  const { data: usageData, isFetching } = useUsageMetrics();
  const [hiddenStates, setHiddenStates] = useState([]);
  const chartRef = useRef<any>(null);
  const { theme } = useUser();

  const labelColor = theme === 'light' ? 'black' : 'white';
  if (isFetching) return <Loader />;
  if (!usageData?.monthlyCosts)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <img src="../assets/images/no-queue.svg" alt="No Queue" />
        <p className="mt-4 text-xl font-semibold">No usage data available.</p>
        <p className="mt-2 text-sm font-medium text-gray">
          Please check back later or contact support if the issue persists.
        </p>
      </div>
    );

  const monthlyLimit = usageData?.monthlyLimit
    ? usageData?.monthlyLimit / 1000000
    : 0;

  const sortedCosts =
    usageData?.monthlyCosts
      ?.filter((x) => x.credits !== 0)
      .sort((a, b) => b.credits - a.credits) || [];

  const top8Costs = sortedCosts.slice(0, 8);
  const otherCosts = sortedCosts.slice(8);

  const top8Credits = top8Costs.reduce(
    (acc, cost) => acc + cost.credits / 1000000,
    0
  );
  const otherCredits = otherCosts.reduce(
    (acc, cost) => acc + cost.credits / 1000000,
    0
  );
  const remainingCredits = monthlyLimit - top8Credits - otherCredits;
  const labels = [
    ...top8Costs.map((cost) => cost.item.name),
    ...(otherCredits ? ['Other'] : []),
    'Remaining credits'
  ];

  const dataValues = [
    ...top8Costs.map((cost) => cost.credits / 1000000),
    ...(otherCredits ? [otherCredits / 1000000] : []),
    remainingCredits < 0 ? 0 : remainingCredits
  ];

  const backgroundColors = [
    'rgb(99 102 241)',
    'rgb(251 113 148)',
    'rgb(255 195 80)',
    'rgb(16 185 129)',
    'rgb(0 126 252)',
    'rgb(230 37 58)',
    'rgb(0 76 151)',
    'rgb(165 168 252)',
    ...(otherCredits ? ['rgb(201 204 210)'] : []),
    'rgb(241 242 243)'
  ];

  const data = {
    labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: backgroundColors
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        display: false, // custom legend
        position: 'right',
        labels: {
          padding: 20,
          usePointStyle: true
        }
      }
    }
  };

  const totalCredits = parseFloat((top8Credits + otherCredits).toFixed(3));

  const textCenter = {
    id: 'textCenter',
    beforeDraw: function (chart: any) {
      const {
        ctx,
        chartArea: { left, right, top, bottom }
      } = chart;
      ctx.restore();

      // Calculate the center of the chart area
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      ctx.fillStyle = labelColor;

      ctx.font = monthlyLimit ? '2.5em sans-serif' : '2em sans-serif';
      const text1 = monthlyLimit
        ? `${((totalCredits / (monthlyLimit ?? 1)) * 100).toFixed(3)}%`
        : `${addCommasToNumber(totalCredits as number)}`;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.fillText(text1, centerX, centerY - 20); // Adjust for vertical centering

      // Total usage text
      ctx.font = '1em sans-serif';
      const text2 = monthlyLimit
        ? `${addCommasToNumber(totalCredits as number)} /`
        : 'credits used';
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.fillText(text2, centerX, centerY + 10); // Adjust for vertical centering

      // Limit text
      ctx.font = '1em sans-serif';
      const text3 = monthlyLimit
        ? `${addCommasToNumber(monthlyLimit ?? 0)} credits`
        : '';
      ctx.fillText(text3, centerX, centerY + 25); // Adjust for vertical centering

      ctx.save();
    }
  };

  const handleLegendClick = (index: number) => {
    if (chartRef.current) {
      const meta = chartRef.current.getDatasetMeta(0);
      meta.data[index].hidden = !meta.data[index].hidden;
      chartRef.current.update();

      const newHiddenStates: any = [...hiddenStates];
      newHiddenStates[index] = !newHiddenStates[index];
      setHiddenStates(newHiddenStates);
    }
  };

  return (
    <div className="pb-3">
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Settings</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">Usage</h2>
        </div>
      </Header>
      <div className="roundedContainer mx-8 mt-8 py-4 px-6  bg-custom-bg">
        <div className="flex flex-col xl:flex-row justify-between gap-2 mt-2">
          <Doughnut
            ref={chartRef}
            data={data}
            options={options as any}
            plugins={[textCenter]}
            className="py-2 max-w-[95%] xl:max-w-[50%] max-h-[400px] min-h-[400px]"
          />
          <div className="flex flex-col gap-2">
            <h6>Your overall total used for this month</h6>
            <div>
              Hover over the segments in the chart to show the value of the
              credits used by each provider. To increase your credit limit,
              please contact our sales team{' '}
              <span className="text-cta">sales@checkstep.com</span>
            </div>
            <CustomLegend
              labels={data.labels}
              backgroundColors={data.datasets[0].backgroundColor}
              hiddenStates={hiddenStates}
              handleClick={handleLegendClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usage;
