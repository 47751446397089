import { useApiClient } from '@hooks/useApiClient';
import { ContentCase } from '@api/types';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '@context/UserProvider';

const useContentCaseApi = () => {
  const apiClient = useApiClient();
  const { platform } = useUser();

  const useContentEvent = ({
    contentType,
    contentId,
    qa = false,
    hideEvaluations = false
  }: {
    contentType?: string;
    contentId?: string;
    qa?: boolean;
    hideEvaluations?: boolean;
  }) =>
    useQuery({
      queryKey: [
        'contentEvent',
        platform?.id,
        contentType,
        contentId,
        qa,
        hideEvaluations
      ],
      queryFn: async () => {
        if (contentType && contentId) {
          const { data } = await apiClient.get<ContentCase>(
            `/review/platforms/${platform?.id}/content-cases`,
            {
              params: {
                complex_type: contentType,
                content_id: contentId,
                qa,
                hide_evaluations: hideEvaluations
              }
            }
          );
          return data;
        }
      },
      enabled: !!platform?.id && !!contentType && !!contentId
    });

  return {
    useContentEvent
  };
};

export { useContentCaseApi };
