import { useApiService } from '@api/services';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '@components/shared/Loader';
import { Header } from '@components/shared/Header';
import { useUser } from '@context/UserProvider';
import { Button } from '@components/shared/Buttons';
import { DatePicker } from '@components/Dashboard/DatePicker';
import { useDate } from '@context/DateProvider';
import { Table } from '@components/shared/Table';
import { useEffect, useState } from 'react';
import {
  exportToCsv,
  getSectionData,
  getSectionsConfig
} from '@components/Settings/Transparency/helper';

const TableWrapper = ({
  section,
  title,
  texts
}: {
  section?: any;
  title?: string;
  texts?: string[];
}) => (
  <div>
    {title && <h5 className="my-4">{title}</h5>}
    {texts && (
      <div className="my-4 flex flex-col">
        {texts.map((text) => (
          <p className="my-0.5" key={`tw-${text}`}>
            {text}
          </p>
        ))}
      </div>
    )}
    {section && (
      <div className="moderatorBreakdownTables h-fit w-full mt-4">
        <Table list={section} thStyle="min-w-44" />
      </div>
    )}
  </div>
);

const Transparency = () => {
  const { useTransparencyMetrics } = useApiService();
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid-setting') || '';
  const { platforms } = useUser();
  const platform = platforms?.find((p) => p.id.toString() === platformId);
  const { startDate, endDate } = useDate();
  const { data: report, isPending } = useTransparencyMetrics({
    start: startDate.toISODate(),
    end: endDate.toISODate()
  });
  const [sectionsConfig, setSectionsConfig] = useState<any>();

  useEffect(() => {
    setSectionsConfig(getSectionsConfig(report, platform?.name));
  }, [report]);

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">{platform?.name}</div>
          <h2 className="mb-3 text-[26px] lg:text-[32px]">
            Transparency Report
          </h2>
        </div>
        <div className="gap-1 flex flex-wrap justify-end z-[99999]">
          <div className="min-w-max min-h-full">
            <DatePicker />
          </div>
          <Button
            style="button font-bold py-2"
            onClick={() => exportToCsv(report, sectionsConfig)}
            title="Export"
          />
        </div>
      </Header>
      {isPending ? (
        <Loader />
      ) : (
        <div className="p-8 flex flex-col gap-8">
          {sectionsConfig?.map((section: any) => (
            <div key={section.title}>
              <h2>{section.title}</h2>
              <TableWrapper
                title={section.sub_title}
                section={
                  section.dataPath && report && getSectionData(report, section)
                }
                texts={section.texts}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Transparency;
