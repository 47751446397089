import { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { PolicyRuleTable } from '@components/Policy/PolicyRuleTable';
import { useUser } from '@context/UserProvider';
import { marked } from 'marked';
import { IoMdAdd } from 'react-icons/io';
import { MdHistory } from 'react-icons/md';
import { Dialog } from '@components/shared/Dialog';
import { AddNewRuleForm } from '@components/Policy/PolicyActions/AddNewRule';
import { useApiService } from '@api/services';
import { useQueryClient } from '@tanstack/react-query';
import { Loader } from '@components/shared/Loader';
import { PolicyRuleType } from '@typeDef/Policy';
import { Button } from '@components/shared/Buttons';
import { RulesHistory } from '@components/Policy/PolicyActions/RulesHistory';
import { strategiesIcons } from '@components/Policy/constant';
import { Header } from '@components/shared/Header';
import { RulesConfirmation } from '@components/Policy/PolicyActions/RulesConfirmation';
import { v4 as uuid } from 'uuid';

const PolicyItem = () => {
  const queryClient = useQueryClient();
  const { platform, user } = useUser();
  const { policyId } = useParams();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const { putPolicyRule, usePolicy, useStrategies } = useApiService();
  const [ruleSelected, setRuleSelected] = useState<PolicyRuleType | null>(null);
  const [rulesList, setRulesList] = useState<PolicyRuleType[] | []>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { data: strategyList } = useStrategies();

  const {
    data: policy,
    error,
    isLoading
  } = usePolicy({ code: policyId || '' });

  useEffect(() => {
    if (strategyList) {
      const rules: PolicyRuleType[] = [];
      policy?.policy_rules?.forEach((x) => {
        rules.push({
          ...x,
          lower_threshold: x.lower_threshold * 100,
          upper_threshold: x.upper_threshold * 100,
          uuid: uuid(),
          strategyIcon: strategyList
            .filter((s) => s.labels?.includes(x.label || ''))
            .map((p) => strategiesIcons[p.type] || '/favicon-32x32.png')
        });
      });
      return setRulesList(rules);
    }
  }, [policy, strategyList]);

  if (isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (!policy) return <div>No policy rules found</div>;
  if (error) return <Navigate to={`/policies?pid=${platform?.id}`} />;

  const onSubmit = () => {
    if (!rulesList) return;

    const rules = rulesList
      .filter((rule: PolicyRuleType) => rule.status !== 'deleted')
      .map((rule: PolicyRuleType) => ({
        lower_threshold: rule.lower_threshold / 100,
        upper_threshold: rule.upper_threshold / 100,
        label: rule.label,
        field: rule.field,
        strategy: rule.strategy,
        content_type: rule.content_type
      }));
    putPolicyRule.mutate(
      { rules, code: policyId || '' },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({ queryKey: ['Policy'] });
          setShowConfirmation(false);
        }
      }
    );
  };

  const getIndex = (rule: PolicyRuleType) =>
    rulesList?.findIndex(
      (x: PolicyRuleType) =>
        x.label === rule.label && x.content_type === rule.content_type
    );

  const closeDialogs = () => {
    setShowAdd(false);
    setShowEdit(false);
    setRuleSelected(null);
  };

  const updateRules = (rule?: PolicyRuleType, index?: number) => {
    if (rule && !!index?.toString()) {
      rulesList[index] = rule;
      setRulesList([...rulesList]);
    }
    closeDialogs();
  };

  const onDiscard = (rule: PolicyRuleType) => {
    const index = getIndex(rule);

    if (!rule.status || index < 0) return;

    if (rule?.status === 'added') {
      rulesList?.splice(index, 1);
      setRulesList([...rulesList]);
    }

    if (rule.status === 'edited') {
      updateRules(rule.original, index);
    }

    if (rule.status === 'deleted') {
      delete rule.status;
      updateRules(rule, index);
    }
  };

  const onEdit = (rule: PolicyRuleType) => {
    const index = getIndex(rule?.original || rule);
    const updatedRule = { ...rule, status: 'edited' };
    updateRules(updatedRule, index);
  };

  const onDelete = (rule: PolicyRuleType) => {
    const index = getIndex(rule);
    const updatedRule = { ...rule, status: 'deleted' };
    updateRules(updatedRule, index);
  };

  const onAdd = (rule: PolicyRuleType) => {
    setRulesList([...rulesList, { ...rule, status: 'added', uuid: uuid() }]);
    closeDialogs();
  };
  return (
    <div className="mt-1 flex-shrink">
      <Header>
        <div className="flex flex-col">
          <div className="text-cta font-bold">Policy</div>
          <h2 className="overflow-hidden min-w-[160px] line-clamp-3">
            {policy.name}{' '}
            <span className="inline text-sm"> - {policy.code}</span>
          </h2>
        </div>
      </Header>
      <div className="px-8 mt-4">
        <h5>Description</h5>
        <div
          id="policy"
          className="my-8 prose max-w-none text-text-white"
          dangerouslySetInnerHTML={{
            __html: marked.parse(policy?.description)
          }}
        />
        <div className="flex justify-between item-start pb-3">
          <h5 className="my-2">Rules</h5>
          {user?.isUserAdmin && (
            <div className="flex gap-3">
              <Button
                hiddenTitle="show policy history"
                onClick={() => setShowHistory(!showHistory)}
                style="rounded-sm text-sm font-bold flex items-center h-8 px-2 buttonOutlined"
              >
                <MdHistory size="20" className="mr-1" /> History
              </Button>
              <Button
                style="buttonOutlined rounded-sm text-sm font-bold flex items-center h-8 px-2"
                hiddenTitle="add policy Rule"
                onClick={() => setShowAdd(!showAdd)}
              >
                <IoMdAdd size="18" />
                New rule
              </Button>
            </div>
          )}
        </div>
        <PolicyRuleTable
          rulesList={rulesList}
          onDiscard={onDiscard}
          onDelete={onDelete}
          onEdit={onEdit}
          show={showEdit}
          setShow={setShowEdit}
          ruleSelected={ruleSelected}
          setRuleSelected={setRuleSelected}
        />
      </div>
      <Dialog show={showAdd} close={() => setShowAdd(false)}>
        <AddNewRuleForm
          onAdd={onAdd}
          strategies={strategyList}
          existingRules={rulesList}
        />
      </Dialog>
      <Dialog show={showHistory} close={() => setShowHistory(false)}>
        <RulesHistory policy={policyId} />
      </Dialog>
      <Dialog
        show={showConfirmation}
        close={() => setShowConfirmation(false)}
        dialogStyle="w-1/3 bg-custom-bg"
      >
        <RulesConfirmation
          rulesList={rulesList}
          isLoading={putPolicyRule?.isPending}
          onSubmit={onSubmit}
          setShowConfirmation={setShowConfirmation}
        />
      </Dialog>
      {rulesList.filter((x: PolicyRuleType) => !!x.status).length > 0 && (
        <Button
          title="Publish changes"
          style="float-right my-8 mr-8 font-bold button py-2"
          onClick={() => setShowConfirmation(true)}
        />
      )}
    </div>
  );
};

export default PolicyItem;
