/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QualityCheckOptions {
    UNCHECKED = 'unchecked',
    CHECKED = 'checked',
    PASSED = 'passed',
    FAILED = 'failed',
}
