import { useApiService } from '@api/services';
import { OrganizationMemberRole, OrganizationMemberUpdate } from '@api/types';
import { Dropdown } from '@components/shared/Dropdown';
import { capitalizeFirstLetter, getFullNameFromEmail } from '@utils/string';
import { DateTime } from 'luxon';
import {
  FaCircle,
  FaEnvelope,
  FaUser,
  FaUserCheck,
  FaUserLock
} from 'react-icons/fa6';
import { OrganizationMember as OrgMember } from '@api/types';

const StatusBadge = (status: string) => {
  const getColor = () => {
    switch (status) {
      case 'disabled':
        return 'text-error';
      case 'confirmed':
        return 'text-success';
      case 'pending':
        return 'text-alert';
    }
  };

  return (
    <span className="flex gap-1.5 text-sm">
      <FaCircle className={getColor()} size={17} />
      {capitalizeFirstLetter(status)}
    </span>
  );
};

const OrganizationMember = ({
  member,
  refetchMembers
}: {
  member: OrgMember;
  refetchMembers: () => void;
}) => {
  const { useUpdateMember } = useApiService();
  const isUserDisabled = member.status === 'disabled';
  const memberOptions = new Map();

  const updateMemberHandler = (member: OrganizationMemberUpdate) =>
    useUpdateMember.mutate(member, {
      onSuccess: async () => refetchMembers()
    });

  Object.values(OrganizationMemberRole)
    .filter((m: string) => m !== member.role)
    .map((x) =>
      memberOptions.set(`Make ${x}`, { enabled: true, role: x, id: member.id })
    );

  isUserDisabled
    ? memberOptions.set('Enable', {
        ...member,
        enabled: true
      })
    : memberOptions.set('Disable', {
        ...member,
        enabled: false
      });

  return (
    <div className="text-text-white bg-primary-50 min-w-[320px] h-48 rounded-lg flex items-center flex-grow">
      <div className="flex flex-col w-full p-4">
        <div className="flex justify-between relative">
          <h5 className="line-clamp-1">{getFullNameFromEmail(member.email)}</h5>
          <Dropdown
            title={
              isUserDisabled ? (
                <FaUserLock size={18} />
              ) : (
                <FaUserCheck size={18} />
              )
            }
            onAction={(option) =>
              updateMemberHandler(memberOptions.get(option))
            }
            list={[...memberOptions.keys()]}
            buttonStyle="buttonOutlined"
            listStyle="border border-neutral-200 absolute rounded-md overflow-hidden right-0"
            arrow={false}
          />
        </div>
        <div className="flex flex-row gap-1.5 text-sm line-clamp-1">
          <div>
            <FaEnvelope size={17} />
          </div>
          {member.email}
        </div>
        <div className="flex flex-row gap-1.5 text-sm">
          <FaUser size={17} />
          {capitalizeFirstLetter(member.role)}
        </div>
        <div>{StatusBadge(member.status)}</div>
        <div className="flex flex-row justify-between mt-2">
          <span className="flex gap-1 text-xs font-semibold">
            Signed up:{' '}
            <div className="font-medium">
              {DateTime.fromISO(member.signedUp).toFormat('MM/dd/yy')}
            </div>
          </span>
          <span className="flex gap-1 text-xs font-semibold">
            Last updated:{' '}
            <div className="font-medium">
              {DateTime.fromISO(member.lastUpdated).toFormat('MM/dd/yy')}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export { OrganizationMember };
