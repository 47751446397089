/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeSeriesEvents } from './TimeSeriesEvents';
export type TimeSeriesResponse_TimeSeriesEvents_ = {
    tz: string;
    start: string;
    end: (string | null);
    interval: TimeSeriesResponse_TimeSeriesEvents_.interval;
    dimensions?: Array<string>;
    series?: Array<TimeSeriesEvents>;
};
export namespace TimeSeriesResponse_TimeSeriesEvents_ {
    export enum interval {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
    }
}

