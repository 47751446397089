import { useParams, useNavigate } from 'react-router-dom';
import { ModeratorReview } from '@components/Review';
import { useApiService } from '@api/services';
import { Loader } from '@components/shared/Loader';
import { useUser } from '@context/UserProvider';

const ReviewSpecificEvent = () => {
  const navigate = useNavigate();
  const { platform } = useUser();
  const { queueId, contentId, ct } = useParams();

  const {
    useAssignCase,
    useContentEvent,
    useQueueDescription,
    useAuthorHistory
  } = useApiService();

  const { error, isPending: isPendingAssign } = useAssignCase({
    complexType: ct,
    contentId: contentId
  });

  const { data: queue, isLoading: isLoadingQueue } = useQueueDescription({
    queueId
  });

  const {
    data: contentCase,
    isFetching: isLoadingCs,
    error: contentCaseError
  } = useContentEvent({
    contentType: ct,
    contentId: contentId,
    qa: queue?.qa,
    hideEvaluations: queue?.hideEvaluations
  });

  const { data: authorHistory, isLoading: isAuthorHistoryLoading } =
    useAuthorHistory({ authorId: contentCase?.contents?.[0]?.author ?? '' });

  if (
    isPendingAssign ||
    isLoadingCs ||
    isLoadingQueue ||
    isAuthorHistoryLoading
  )
    return <Loader />;
  if (error || contentCaseError) return navigate(`/queues?pid=${platform?.id}`);
  if (!contentCase || (!queue && queueId !== 'noqueue'))
    return <div className="displayCenter">No data found</div>;

  return (
    <ModeratorReview
      queueId={queueId}
      queue={queue}
      contentCase={contentCase}
      minorStrikes={authorHistory?.minor_strikes}
      majorStrikes={authorHistory?.major_strikes}
      strikes={authorHistory?.strikes}
      incidents={authorHistory?.incidents}
      cases={authorHistory?.cases}
      comments={contentCase?.comments}
      onReviewCallback={() =>
        navigate(
          !queue
            ? '/explore'
            : `/queues/bulklist/${queueId}?pid=${platform?.id}`
        )
      }
    />
  );
};

export default ReviewSpecificEvent;
