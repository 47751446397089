import QualityMetrics from '@components/Dashboard/QualityMetrics';
import { Scheduler } from '@components/Dashboard/Scheduler';
import {
  getActiveModerators,
  getActiveModeratorBuckets
} from '@components/Dashboard/helper';
import { useApiService } from '@api/services';
import { useDate } from '@context/DateProvider';
import { useOutletContext } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { ModeratorBreakdown } from '@components/Dashboard/ModeratorBreakdown';
import { useQueues } from '@context/QueuesProvider';

type OutlineType = {
  moderatorIds: string[];
  queueIds: string[];
};
const Workforce = () => {
  const { moderators } = useUser();
  const { queues } = useQueues();
  const { moderatorIds, queueIds } = useOutletContext<OutlineType>();
  const { startDate, endDate } = useDate();

  const { useModeratorsTimeTrack } = useApiService();
  const { data: moderatorTimeTrack, error: moderatorTimeTrackError } =
    useModeratorsTimeTrack({
      startDate,
      endDate,
      queueIds: [...queueIds],
      moderatorIds: [...moderatorIds]
    });

  if (!queues) return null;

  return (
    <div>
      <div className="flex flex-col mlg:flex-row gap-[20px] w-full">
        <QualityMetrics />
        <Scheduler
          rawData={getActiveModeratorBuckets(
            moderatorTimeTrack?.buckets,
            moderators
          )}
          moderatorsDetails={getActiveModerators(
            moderatorTimeTrack?.buckets,
            moderators
          )}
          errorFetchingData={moderatorTimeTrackError}
        />
      </div>
      <div className="dashboardCharts">
        <ModeratorBreakdown
          queueIds={[...queueIds]}
          queues={queues}
          moderators={moderators}
          moderatorIds={[...moderatorIds]}
          durationStart={startDate}
          durationEnd={endDate}
        />
      </div>
    </div>
  );
};

export default Workforce;
