import { useEffect } from 'react';
import { useApiService } from '@api/services';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { SQSConfig, WebhookConfig } from '@api/types';
import { Loader } from '@components/shared/Loader';
import { SubmitButtons } from '@components/shared/Buttons';
import { Header } from '@components/shared/Header';
import { useUser } from '@context/UserProvider';

const Sqs = () => {
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid-setting') || '';
  const { useSqs } = useApiService();
  const { data: sqs, isLoading, error } = useSqs({ platformId });

  if (isLoading) return <Loader />;
  if (error || !sqs)
    return (
      <div className="text-alert m-3">Error: could not retrieve settings</div>
    );

  return <SqsForm sqs={sqs} platformId={platformId} />;
};

type FormProps = {
  sqs: SQSConfig;
  platformId: string;
};

const SqsForm = ({ sqs, platformId }: FormProps) => {
  const queryClient = useQueryClient();
  const { platforms } = useUser();
  const platform = platforms?.find((p) => p.id.toString() == platformId);
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: sqs
  });
  const { putSqs } = useApiService();

  useEffect(() => {
    reset(sqs);
  }, [sqs]);

  const updateSqs = (data: WebhookConfig) => {
    putSqs.mutate(
      {
        platformId,
        ...data
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: ['useSqs', platformId]
          });
        },
        onError: (err) => {
          console.log('error', err);
        }
      }
    );
  };

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">{platform?.name}</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">
            Amazon SQS Integration
          </h2>
        </div>
      </Header>
      <form
        className="flex gap-4 flex-col p-8"
        onSubmit={handleSubmit(updateSqs)}
      >
        <div className="grid grid-cols-[max-content_auto] gap-4">
          <div className="flex items-center">
            <div className="font-bold">URL</div>
          </div>
          <div className="flex items-center">
            <input
              className="roundedContainer pl-2 pb-2 w-full"
              {...register('sqsUrl', { required: true })}
            />
          </div>

          <div className="flex items-center">
            <div className="font-bold">Role Arn</div>
          </div>
          <div className="flex items-center">
            <input
              className="roundedContainer pl-2 pb-2 w-full"
              {...register('roleArn', { required: true })}
            />
          </div>
        </div>
        <div>
          <div className="font-bold">Triggers</div>
          <div className="mt-1">
            <div className="indent-4">
              <input type="checkbox" {...register('types.decision')} />
              <label className="ml-2">Decision Taken</label>
            </div>
            <div className="indent-4">
              <input type="checkbox" {...register('types.incidentClosed')} />
              <label className="ml-2">Incident Closed</label>
            </div>
            <div className="indent-4">
              <input
                type="checkbox"
                className="text-cta p-2"
                {...register('types.analysedContent')}
              />
              <label className="ml-2">Content Analysed</label>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <SubmitButtons
            onSubmit={() => handleSubmit(updateSqs)}
            submitLabel="Save"
            disabled={!formState.isValid}
            isLoading={putSqs?.isPending}
            submitStyle="font-bold py-2"
          />
        </div>
      </form>
    </>
  );
};

export default Sqs;
