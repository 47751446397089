import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useApiService } from '@api/services';
import { QueuesContextType } from '@typeDef/queues';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

export const QueuesContext = createContext<QueuesContextType>(undefined!);

const QueuesProvider = ({ children }: Props) => {
  const location = useLocation();

  const regex = /(platforms\/\d+|queues)/;
  const isEnabled = regex.test(location.pathname);

  const { useQueues, useQueuesCount } = useApiService();
  const {
    data: queues,
    error,
    isLoading: isLoadingQueues
  } = useQueues({ enabled: true });

  const { data: queueCount, isPending: isLoadingCount } =
    useQueuesCount(isEnabled);

  const mappedQueues = queues?.map((q) => ({
    ...q,
    ...queueCount?.stats?.[q.id]
  }));

  const value = useMemo(
    () => ({
      queueCount,
      mappedQueues,
      isLoadingCount,
      queues,
      error,
      isLoadingQueues
    }),
    [queueCount, queues]
  );

  return (
    <QueuesContext.Provider value={value}>{children}</QueuesContext.Provider>
  );
};

function useQueues() {
  const context = useContext(QueuesContext);
  if (context === undefined) {
    // Adding custom error
    throw new Error('useQueues must be used within a QueuesProvider');
  }
  return context;
}

export { QueuesProvider, useQueues };
