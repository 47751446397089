import { useState } from 'react';
import { Content } from '@components/shared/Content';
import { CellWithCopy } from '@components/shared/Table/CellWithCopy';
import { PlatformTime } from '@components/shared/PlatformTime';
import { IncidentStatus } from '@components/shared/IncidentStatus';
import { Dialog } from '@components/shared/Dialog';
import { ViewEvent } from '@components/shared/EventCard/ViewEvent';
import { ContentCaseShort, ContentCase } from '@api/types';
import { BulkReviewCase } from '@typeDef/Review';
import { Button } from '@components/shared/Buttons';
import { FaChevronRight } from 'react-icons/fa';
import { ContentFields } from '@components/shared/Content/Fields';
import { FaCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@context/UserProvider';

const EventCard = ({
  contentCase,
  selectedCS,
  setSelectedCS,
  isNotQA = true,
  queueId
}: {
  contentCase: ContentCaseShort;
  selectedCS?: Map<string, BulkReviewCase | ContentCase>;
  setSelectedCS?: (selectedCS: Map<string, any>) => void;
  isNotQA?: boolean;
  queueId?: string;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const content = contentCase?.contents?.at(-1);
  const incident = contentCase?.incidents?.at(-1);
  const selected = selectedCS?.has(contentCase?.cid);
  const navigate = useNavigate();
  const { platform } = useUser();

  const onClick = () => {
    if (!isNotQA) return;
    selected
      ? selectedCS?.delete(contentCase?.cid)
      : selectedCS?.set(contentCase?.cid, contentCase);
    setSelectedCS?.(new Map(selectedCS));
  };

  return (
    <div
      className={`w-full 2xl:w-[49.4%] border-neutral-200 border rounded-md p-8 bg-custom-bg hover:shadow-md ${isNotQA && 'cursor-pointer'} flex flex-col`}
      onClick={onClick}
    >
      <div className="flex flex-row w-full">
        <div className="flex items-center justify-between mb-2 w-full">
          {!Object.keys(contentCase).includes('_primary_term') && (
            <div className="flex">
              <IncidentStatus incident={incident} />
              <h5 className="ml-1">
                {incident?.status
                  ? `Incident ${incident?.status}`
                  : 'No Incident'}
              </h5>
            </div>
          )}

          {setSelectedCS && isNotQA && (
            <div
              className={`ml-auto font-medium w-[24px] h-[24px] ${selected ? 'bg-cta' : 'bg-primary-50 border border-primary'} rounded-sm hover:bg-cta flex items-center justify-center cursor-pointer`}
            >
              {selected && <FaCheck size={16} color="white" />}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center mlg:items-start justify-between mlg:flex-row overflow-y-visible w-full text-left">
        <div className="mlg:px-4 w-[68%] overflow-y-visible break-words cursor-default">
          {content?.fields ? (
            <ContentFields fields={content.fields} />
          ) : (
            <Content fields={content?.body} type={contentCase.ct} />
          )}
        </div>
        <div className="w-[66%] mlg:w-[300px] mlg:min-w-[20%] flex-col gap-3 flex mt-3 mlg:max-w-[30%]">
          <div>
            <p className="font-bold">Created at</p>
            <PlatformTime dt={contentCase.ts} />
          </div>
          {content?.author && (
            <div>
              <p className="font-bold">User ID</p>
              <CellWithCopy val={content?.author || ''} width="w-full mr-2" />
            </div>
          )}
          <div>
            <p className="font-bold">Content ID</p>
            <CellWithCopy val={contentCase.cid} width="w-full mr-2" />
          </div>
          <div>
            <p className="font-bold">Content Type</p>
            {contentCase.ct}
          </div>
          <div className="flex flex-col gap-2 items-start">
            <Button
              style="buttonOutlined flex justify-center items-center whitespace-nowrap text-md gap-1 w-36 h-9"
              onClick={(e) => {
                e.stopPropagation();
                setShow(true);
              }}
              hiddenTitle="View More"
            >
              View More{' '}
              <FaChevronRight className="min-w-[10px]" data-testid="more" />
            </Button>
            <Button
              style="buttonOutlined text-md w-36 h-9"
              onClick={() => {
                navigate(
                  `/queues/${queueId ?? 'noqueue'}/review/event/${encodeURIComponent(contentCase.cid)}/${encodeURIComponent(contentCase.ct)}?pid=${platform?.id}`
                );
              }}
              hiddenTitle="Moderate case"
            >
              Moderate
            </Button>
          </div>
        </div>
        <Dialog
          show={show}
          close={() => setShow(false)}
          dialogStyle="w-2/3 bg-custom-bg relative cursor-default"
        >
          <div className="px-6 pb-2">
            <ViewEvent contentCase={contentCase} setShow={setShow} />
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export { EventCard };
