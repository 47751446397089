import { useState } from 'react';
import { useApiService } from '@api/services';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Loader } from '@components/shared/Loader';
import { ChartInterval } from '@components/Dashboard/ChartInterval';
import { formatTime } from '@utils/date';
import { TimeSeriesEvents } from '@api/types';
import { EventChartProp } from '@typeDef/Dashboard';
import { ChartHandler } from '@components/Dashboard/ChartHandler';
import { useUser } from '@context/UserProvider';
ChartJS.register(...registerables);

const EventChart = ({ start, end }: EventChartProp) => {
  const { useEvents } = useApiService();
  const [interval, setInterval] = useState<string>('day');
  const intervals = ['day', 'week', 'month'];
  const { theme } = useUser();

  const {
    data: events,
    error: eventsError,
    isLoading: eventsIsLoading
  } = useEvents({ interval, start, end });

  const options = {
    resizeDelay: 150,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: { color: `${theme === 'dark' && 'white'}` },
        grid: { display: false },
        border: { display: false }
      },
      y: {
        ticks: { color: `${theme === 'dark' && 'white'}` },
        grid: { display: false },
        border: { display: false }
      }
    },
    plugins: {
      legend: {
        onHover: (event: any) => {
          event.native.target.style.cursor = 'pointer';
        },
        onLeave: (event: any) => {
          event.native.target.style.cursor = 'default';
        },
        labels: {
          color: `${theme === 'dark' && 'white'}`,
          useBorderRadius: true,
          borderRadius: 4,
          boxHeight: 10
        }
      }
    }
  };

  const chartData = {
    labels: events?.series?.map((x: TimeSeriesEvents) =>
      formatTime(x.time, events?.interval)
    ),
    datasets: [
      {
        label: 'Events',
        data: events?.series?.map((x: TimeSeriesEvents) => x.events),
        backgroundColor: 'rgb(129 133 248)',
        hoverBackgroundColor: 'rgb(99 102 241)',
        borderRadius: 10
      },
      {
        data: events?.series?.map((x: TimeSeriesEvents) => x.incidents),
        label: 'Incidents',
        backgroundColor: 'rgb(244 63 94)',
        hoverBackgroundColor: 'rgb(245 81 105)',
        borderRadius: 10
      }
    ]
  };

  return (
    <div className="eventChartContainer border border-lightgray w-full mlg:max-w-[48%] relative bg-custom-bg">
      <ChartInterval
        title="Events and incidents"
        intervals={intervals}
        interval={interval}
        setInterval={setInterval}
      />
      <ChartHandler error={eventsError}>
        <div className="chartContainer">
          {eventsIsLoading ? (
            <Loader />
          ) : (
            <Chart
              data-testid="chart"
              type="bar"
              options={options}
              data={chartData}
              className="max-w-full h-[252px] bg-custom-bg"
            />
          )}
        </div>
      </ChartHandler>
    </div>
  );
};

export { EventChart };
