import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ModeratorReview } from '@components/Review';
import { useApiService } from '@api/services';
import { Loader } from '@components/shared/Loader';
import { useUser } from '@context/UserProvider';

const ReviewContent = () => {
  const navigate = useNavigate();
  const { platform } = useUser();
  const { queueId } = useParams();
  const { useNextCase, useQueueDescription, useAuthorHistory } =
    useApiService();

  const {
    data: { case: contentCase } = {},
    isLoading: isLoadingCase,
    error,
    refetch: refetchQueueEvent
  } = useNextCase({ queueId });

  const { data: queue, isLoading: isLoadingQueue } = useQueueDescription({
    queueId
  });

  const { data: authorHistory, isLoading: isAuthorHistoryLoading } =
    useAuthorHistory({ authorId: contentCase?.contents?.[0]?.author ?? '' });

  useEffect(() => {
    if ((error || !contentCase) && !isLoadingCase) {
      const errorState = {
        status: 'nocase',
        title: 'End of queue',
        description:
          'Any remaining cases may be currently assigned, pending decision or skipped by you'
      };
      return navigate(`/error?pid=${platform?.id}`, {
        state: errorState
      });
    }
  }, [error, contentCase, isLoadingCase]);

  if (isLoadingCase || isLoadingQueue || isAuthorHistoryLoading)
    return <Loader />;

  if (contentCase)
    return (
      <ModeratorReview
        queueId={queueId}
        queue={queue}
        contentCase={contentCase}
        minorStrikes={authorHistory?.minor_strikes}
        majorStrikes={authorHistory?.major_strikes}
        strikes={authorHistory?.strikes}
        incidents={authorHistory?.incidents}
        cases={authorHistory?.cases}
        comments={contentCase?.comments}
        showQueueNav={true}
        onReviewCallback={refetchQueueEvent}
      />
    );
};

export default ReviewContent;
