import { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useDate } from '@context/DateProvider';
import 'react-day-picker/dist/style.css';
import { Dropdown } from '@components/shared/Dropdown';
import { Button } from '@components/shared/Buttons';
import { DateTime } from 'luxon';
import { FaCalendarAlt } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';

type DateRanges = {
  [key: string]: number;
};

type Range = {
  from?: Date;
  to?: Date;
};

const DatePicker = () => {
  const dateRanges: DateRanges = {
    Today: 0,
    'Since yesterday': 1,
    'Last 7 days': 7,
    'Last 14 days': 14,
    'Last 30 days': 30,
    'Last 90 days': 90,
    'Last 180 days': 180,
    ...(!location.pathname.includes('explore') ? {} : { 'All time': 0 })
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const { setStartDate, setEndDate, endDate, startDate } = useDate();
  const [selectedRange, setSelectedRange] = useState('All time');
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState('Relative');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [range, setRange] = useState<Range>({
    from: undefined,
    to: undefined
  });

  const noDate = () => {
    setSelectedRange('All time');
    setEndDate();
    setStartDate();
  };

  useEffect(() => {
    const startParam = searchParams.get('startDate');
    const endParam = searchParams.get('endDate');

    if (location.pathname.includes('explore') && !startParam && !endParam) {
      return noDate();
    }

    if (startParam && endParam) {
      setEndDate(DateTime.fromISO(endParam));
      setStartDate(DateTime.fromISO(startParam));
    } else {
      selectRange('Last 7 days');
    }
  }, [location.pathname]);

  const selectRange = (selectedRange: string) => {
    const params: any = {};
    searchParams.forEach((val, key) => (params[key] = val));
    if (selectedRange === 'All time') {
      noDate();
      delete params['startDate'];
      delete params['endDate'];
    }
    if (selectedRange !== 'All time') {
      setEndDate(DateTime.now());
      setStartDate(DateTime.now().minus({ days: dateRanges[selectedRange] }));
      setSelectedRange(selectedRange);
      params['startDate'] = DateTime.now()
        .minus({ days: dateRanges[selectedRange] })
        .toISODate();
      params['endDate'] = DateTime.now().toISODate();
    }
    setSearchParams(params);
  };

  const applyDates = () => {
    if (range?.from) {
      const params: any = {};
      searchParams.forEach((val, key) => (params[key] = val));
      setStartDate(DateTime.fromJSDate(range?.from));
      setEndDate(DateTime.fromJSDate(range?.to ?? range?.from));
      params['startDate'] = DateTime.fromJSDate(range?.from).toISODate();
      params['endDate'] = DateTime.fromJSDate(
        range?.to ?? range?.from
      ).toISODate();
      setSearchParams(params);
    }
  };

  const handleSelect = (date: { from: Date; to: Date }) => {
    if (!date?.from && !date?.to) {
      setRange({
        from: range.from,
        to: undefined
      });
    } else {
      setRange(
        range.from && range.to
          ? {
              from: date.from < range.from ? date.from : date.to,
              to: undefined
            }
          : { from: date.from, to: date.to }
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    open
      ? document.addEventListener('mousedown', handleClickOutside)
      : document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div className="flex h-full items-center relative" ref={dropdownRef}>
      <Button
        hiddenTitle="DatePickerButton"
        onClick={() => setOpen(!open)}
        style="rounded-md bg-custom-bg border border-neutral-200 px-3 h-full flex items-center gap-2"
      >
        <p>
          {startDate?.toFormat?.('MM/dd/yy') ?? '-'} -{' '}
          {endDate?.toFormat?.('MM/dd/yy') ?? '-'}
        </p>
        <div className="flex items-center mb-[3px]">
          <FaCalendarAlt size={15} />
        </div>
      </Button>
      {open && (
        <div className="absolute right-0 bg-custom-bg border border-neutral-200 min-w-72 rounded-md top-10 p-3 zIndexTop">
          <div className="flex flex-row gap-3 mb-3">
            <Button
              style={`py-1 hover:text-cta border-b-2 ${option === 'Relative' ? 'border-cta' : 'border-transparent'}`}
              onClick={() => setOption('Relative')}
            >
              Relative
            </Button>
            <Button
              style={`py-1 hover:text-cta border-b-2 ${option === 'Absolute' ? 'border-cta' : 'border-transparent'}`}
              onClick={() => setOption('Absolute')}
            >
              Absolute
            </Button>
          </div>
          {option === 'Relative' ? (
            <Dropdown
              title={selectedRange}
              list={Object.keys(dateRanges)}
              onAction={selectRange}
              mainClass="p-0 w-full"
              buttonStyle="rounded-md border border-neutral-200 p-2 bg-custom-bg hover:bg-transparent hover:border-neutral-200"
              listStyle="absolute z-40 border bg-custom-bg border-neutral-200 w-36 rounded-md overflow-auto"
              itemStyle="hover:bg-transparent hover:text-cta"
            />
          ) : (
            <div>
              <DayPicker
                mode="range"
                selected={range as any}
                onSelect={handleSelect as any}
                footer={
                  <div className="w-full flex justify-end mt-3">
                    <Button
                      style="button"
                      onClick={applyDates}
                      disabled={!range?.from}
                    >
                      Apply
                    </Button>
                  </div>
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { DatePicker };
