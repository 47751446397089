/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SimpleType {
    TEXT = 'text',
    IMAGE = 'image',
    URI = 'uri',
    VIDEO = 'video',
    AUDIO = 'audio',
    FILE = 'file',
}
